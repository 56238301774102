import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@mui/styles';

import ec_icon from '../images/cir-ec-color-active.png';
import ph_icon from '../images/cir-ph-color-active.png';
import temperature from '../images/temp-wat-active.png';
import thermometer from '../images/temp-active.png';
import humidity from '../images/humid-active.png';
import co2 from '../images/co2-active.png';
import intensity from '../images/intensity.png';
import temperature_control from '../images/cir-temp-water-active.png';
import humidity_control from '../images/cir-humid-active.png';
import co2_control from '../images/cir-co2-active.png';
import intensity_control from '../images/intensity-active.png';
import switch_on from '../images/active-on.png';
import switch_off from '../images/active-off.png';
import bulb_on from '../images/lamp-on.png';
import bulb_off from '../images/lamp-off.png';
import bell_on from '../images/cir-ringing-on.png';
import bell_off from '../images/cir-ringing-off.png';
import signal_1 from '../images/signal-1.png';
import signal_2 from '../images/signal-2.png';
import signal_3 from '../images/signal-3.png';
import signal_4 from '../images/signal-4.png';

const useStyles = () => ({
  online_bg: {
    backgroundColor: `#e1f1e6`
  },
  online_bgAlert: {
    animation: `$blinkingBg 1s infinite`
  },
  offline_bg: {
    backgroundColor: `lightgrey`
  },
  offline_bgAlert: {
    backgroundColor: `#ffaaaa`
  },
  "@keyframes blinkingBg": {
    "0%": {
      color: '#fff',
      backgroundColor: '#FF4842',
    },
    "49%": {
      color: '#7A0C2E',
      backgroundColor: '#FFE7D9',
    },
    "50%": {
      color: '#7A0C2E',
      backgroundColor: '#FFE7D9',
    },
    // "99%": {
    //   color: '#7A0C2E',
    //   backgroundColor: '#FFE7D9',
    // },
    "100%": {
      color: '#fff',
      backgroundColor: '#FF4842',
    },
  },
});

class SummaryCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      device_data: this.getDeviceData()
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.deviceData) !== JSON.stringify(this.props.deviceData)) {
      this.setState({
        device_data: this.getDeviceData()
      });
    }
  }

  getDeviceData() {
    let _deviceData = this.props.deviceData;

    if (!_deviceData.data) {
      _deviceData.data = { status: [] };
      if (_deviceData.productType === 'TH_AMBIENT') {
        _deviceData.data.status = {};
      }
    }

    return _deviceData;
  }

  getDeviceType(prod_type, prod_variant) {
    switch (prod_type) {
      case 'TH_AMBIENT':
        return 'Ambient Sensor';
      case 'TH_AMBIENT_CTRL':
        return 'Ambient Controller';
      case 'TH_DOSER':
        return 'Doser';
      case 'TH_DIMMER':
        return prod_variant === '4CH' ? '4 Channels LED Controller' : prod_variant === '3CH' ? '3 Channels LED Controller' : prod_variant === '2CH' ? '2 Channels LED Controller' : 'LED Controller';
      case 'TH_DATAGATEWAY':
        return 'Data Gateway';
      case 'TH_SWITCH':
        return 'Switch';
      case 'TH_NUTRIENT_SENSOR':
        return 'Nutrient Sensor';
      case 'TH_ALARM':
        return 'Alarm';
      default:
        return 'Unknown Device'
    }
  }

  getEquipmentType(control_type) {
    switch (control_type) {
      case 'aircondition':
        return 'Temperature';
      case 'humidifier':
        return 'Humidifier';
      case 'dehumidifier':
        return 'Dehumidifier';
      case 'co2feeder':
        return 'CO2 Feeder';
      case 'shade':
        return 'Shade';
      default:
        return 'Unmanaged Device'
    }
  }

  getSettingType(control_type) {
    switch (control_type) {
      case 'aircondition':
        return 'TEMP';
      case 'humidifier':
        return 'HUMID';
      case 'dehumidifier':
        return 'HUMID';
      case 'co2feeder':
        return 'CO2';
      case 'shade':
        return 'INT';
      default:
        return 'Unmanaged Setting'
    }
  }

  render() {

    // check for number of sensors
    // let ambClass = "col-4";
    // let ambientSensorCount = 0;
    // try {
    //   if (this.state.device_data.data.status.temperature !== null) ambientSensorCount++;
    //   if (this.state.device_data.data.status.humidity !== null) ambientSensorCount++;
    //   if (this.state.device_data.data.status.co2 !== null) ambientSensorCount++;
    //   if (this.state.device_data.data.status.intensity !== null) ambientSensorCount++;
    // }
    // catch {
    //   // no data yet
    // }

    // switch (ambientSensorCount) {
    //   case 4:
    //     ambClass = "col-3";
    //     break;
    //   default:
    //     ambClass = "col-4";
    // }

    // alarm card color
    let is_alarm = false;
    // TH_ALARM
    if (this.state.device_data.data && this.state.device_data.productType === 'TH_ALARM') {
      for (const channel of this.state.device_data.data.status) {
        if (channel.state) {
          is_alarm = true;
        }
      }
    }
    // TH_AMBIENT
    if (this.state.device_data.data && this.state.device_data.productType === 'TH_AMBIENT') {
      if ((this.state.device_data.data.alarms ?? []).length > 0) {
        is_alarm = true;
      }
    }
    // TH_DOSER
    if (this.state.device_data.data && this.state.device_data.productType === 'TH_DOSER') {
      if ((this.state.device_data.data.alarms ?? []).length > 0) {
        is_alarm = true;
      }
    }
    // TH_DIMMER
    if (this.state.device_data.data && this.state.device_data.productType === 'TH_DIMMER') {
      if ((this.state.device_data.data.alarms ?? []).length > 0) {
        is_alarm = true;
      }
    }
    // TH_SWITCH
    if (this.state.device_data.data && this.state.device_data.productType === 'TH_SWITCH') {
      if ((this.state.device_data.data.alarms ?? []).length > 0) {
        is_alarm = true;
      }
    }

    const { classes } = this.props;
    let card_color_class;
    if (this.state.device_data.data && this.state.device_data.online) {
      card_color_class = is_alarm ? classes.online_bgAlert : classes.online_bg;
    }
    else {
      card_color_class = is_alarm ? classes.offline_bgAlert : classes.offline_bg;
    }

    // project render
    let proj_100_pattern = [
      0, 365, 511, 495, 381, 254, 238, 124, 222, 206, 92, 214, 198, 84, 146,
    ];

    return (
      <React.Fragment>
        <div className={`h-100 card ${card_color_class}`}>
          <Link to={'/app/device/' + this.state.device_data.deviceId} className="card-block stretched-link text-decoration-none"></Link>
          <div className="card-body">
            <div className="row">
              <div className="col-9 text-left">
                <h5>{this.state.device_data.deviceName}</h5>
              </div>
              <div className="col-3 text-right">
                {this.state.device_data.online ?
                  (this.state.device_data.rssi >= -50 ?
                    (<img src={signal_4} alt="Excellent signal" height={24} width={24} />)
                    :
                    this.state.device_data.rssi >= -60 ?
                      (<img src={signal_3} alt="Very gool signal" height={24} width={24} />)
                      :
                      this.state.device_data.rssi >= -70 ?
                        (<img src={signal_2} alt="Good signal" height={24} width={24} />)
                        :
                        (<img src={signal_1} alt="Low signal" height={24} width={24} />)
                  )
                  : (<span className="badge badge-pill badge-danger">Offline</span>)}
              </div>
            </div>
            <h6 className="card-subtitle mb-2 text-muted">{this.getDeviceType(this.state.device_data.productType, this.state.device_data.productVariant)}</h6>

            {this.state.device_data.productType === 'TH_AMBIENT' && (
              <React.Fragment>
                <div className="row d-flex justify-content-between">
                  {this.state.device_data.data.status.temperature !== null && (
                    <div className={`text-center col-3 mt-1`}>
                      <div className="card-text">
                        <img src={thermometer} className="card-icon" alt="Temperature icon" />
                        <p className="pt-3">
                          {[undefined, null].includes(this.state.device_data.data.status.temperature) ? '-' : this.state.device_data.data.status.temperature.toFixed(1)} °C
                        </p>
                      </div>
                    </div>
                  )}

                  {this.state.device_data.data.status.humidity !== null && (
                    <div className={`text-center col-3 mt-1`}>
                      <div className="card-text">
                        <img src={humidity} className="card-icon" alt="Humidity icon" />
                        <p className="pt-3">
                          {[undefined, null].includes(this.state.device_data.data.status.humidity) ? '-' : this.state.device_data.data.status.humidity.toFixed(1)} %RH
                        </p>
                      </div>
                    </div>
                  )}

                  {this.state.device_data.data.status.co2 !== null && (
                    <div className={`text-center col-3 mt-1`}>
                      <div className="card-text">
                        <img src={co2} className="card-icon" alt="CO2 icon" />
                        <p className="pt-3">
                          {[undefined, null].includes(this.state.device_data.data.status.co2) ? '-' : this.state.device_data.data.status.co2.toFixed(0)} ppm
                        </p>
                      </div>
                    </div>
                  )}

                  {this.state.device_data.data.status.intensity !== null && (
                    <div className={`text-center col-3 mt-1`}>
                      <div className="card-text">
                        <img src={intensity} className="card-icon" alt="Intensity icon" />
                        <p className="pt-3">
                          {[undefined, null].includes(this.state.device_data.data.status.intensity) ? '-' : this.state.device_data.data.status.intensity.toFixed(0)} lux
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_AMBIENT_CTRL' && (
              <React.Fragment>
                <div className="row d-flex justify-content-between">

                  {/* {this.state.device_data.data.status && this.state.device_data.data.status.map((device, index) => (
                    <div key={index} className="text-center col-6 mt-1">
                      <p className="card-text">
                        <b>
                          {this.getEquipmentType(device.device)}
                        </b>
                        <br />
                        <img src={device.active ? switch_on : switch_off} width="35%" alt="Power state icon" />
                      </p>
                    </div>
                  ))} */}

                  {this.state.device_data.productVariant && ((this.state.device_data.productVariant || '').split(',')).map((parameter, index) => {
                    if (parameter === 'TEMP') {
                      return (
                        <div className={"text-center col-3 mt-2"}>
                          <div className="card-text">
                            <img src={temperature_control} className="card-icon" alt="Temperature icon" />
                            <p className="pt-3">
                              {/* {this.state.device_data.data.controls && this.state.device_data.data.controls.temperature ? "Enabled" : "Disabled"} */}
                            </p>
                          </div>
                        </div>
                      )
                    }
                    if (parameter === 'HUMID') {
                      return (
                        <div className={"text-center col-3 mt-2"}>
                          <div className="card-text">
                            <img src={humidity_control} className="card-icon" alt="Humidity icon" />
                            <p className="pt-3">
                              {/* {this.state.device_data.data.controls && this.state.device_data.data.controls.humidity ? "Enabled" : "Disabled"} */}
                            </p>
                          </div>
                        </div>
                      )
                    }
                    if (parameter === 'CO2') {
                      return (
                        <div className={"text-center col-3 mt-2"}>
                          <div className="card-text">
                            <img src={co2_control} className="card-icon" alt="CO2 icon" />
                            <p className="pt-3">
                              {/* {this.state.device_data.data.controls && this.state.device_data.data.controls.co2 ? "Enabled" : "Disabled"} */}
                            </p>
                          </div>
                        </div>
                      )
                    }
                    if (parameter === 'INT') {
                      return (
                        <div className={"text-center col-3 mt-2"}>
                          <div className="card-text">
                            <img src={intensity_control} className="card-icon" alt="Temperature icon" />
                            <p className="pt-3">
                              {/* {this.state.device_data.data.controls && this.state.device_data.data.controls.intensity ? "Enabled" : "Disabled"} */}
                            </p>
                          </div>
                        </div>
                      )
                    }
                    return null;
                  })}

                  {/* Empty Tag for spacing */}
                  {(this.state.device_data.productVariant || '').split(',').length === 2 && (
                    <div className={"text-center col-3 mt-2"}>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_DOSER' && (
              <React.Fragment>
                <div className="row d-flex justify-content-between">
                  <div className="text-center col-3 mt-2">
                  <p className="card-text">
                      <img src={ec_icon} className="card-icon" alt="EC icon" />
                    </p>
                  </div>
                  <div className="text-center col-3 mt-2">
                  <p className="card-text">
                      <img src={ph_icon} className="card-icon" alt="pH icon" />
                    </p>
                  </div>
                  <div className="text-center col-3 mt-0">
                    <p className="card-text">
                      <img src={temperature} className="card-icon" alt="Temperature icon" />
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="text-center col-3 mt-1">
                    <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.ec) ? '-' : this.state.device_data.data.status.ec.toFixed(0)} μS/cm</p>
                  </div>
                  <div className="text-center col-3 mt-1">
                    <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.ph) ? '-' : this.state.device_data.data.status.ph.toFixed(2)}</p>
                  </div>
                  <div className="text-center col-3 mt-1">
                    <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.temperature) ? '-' : this.state.device_data.data.status.temperature.toFixed(1)} °C</p>
                  </div>
                </div>
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_NUTRIENT_SENSOR' && (
              <React.Fragment>
                <div className="row">
                  <div className="text-center col-4 mt-2">
                    <h2 className="mb-0">EC</h2>
                  </div>
                  <div className="text-center col-4 mt-2">
                    <h2 className="mb-0">pH</h2>
                  </div>
                  <div className="text-center col-4 mt-0">
                    <p className="card-text">
                      <img src={temperature} className="card-icon" alt="Temperature icon" />
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="text-center col-4 mt-1">
                    <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.ec) ? '-' : this.state.device_data.data.status.ec.toFixed(0)} μS/cm</p>
                  </div>
                  <div className="text-center col-4 mt-1">
                    <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.ph) ? '-' : this.state.device_data.data.status.ph.toFixed(2)}</p>
                  </div>
                  <div className="text-center col-4 mt-1">
                    <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.temperature) ? '-' : this.state.device_data.data.status.temperature.toFixed(1)} °C</p>
                  </div>
                </div>
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_DIMMER' && this.state.device_data.productVariant === '4CH' && (
              <React.Fragment>
                <div className="row">
                  {this.state.device_data.data.status && this.state.device_data.data.status.map((channel, index) => (
                    <div key={index} className="text-center col-3 mt-1">
                      <p className="card-text"><b>CH {channel.channel + 1}</b>
                        <br />
                        <img src={channel.power ? bulb_on : bulb_off} className="card-icon" alt="LED state icon" />
                        {channel.power && (
                          <React.Fragment>
                            <br />
                            {channel.intensity}%
                          </React.Fragment>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_DIMMER' && this.state.device_data.productVariant === '3CH' && (
              <React.Fragment>
                <div className="row">
                  {this.state.device_data.data.status && this.state.device_data.data.status.map((channel, index) => (
                    <div key={index} className="text-center col-4 mt-1">
                      <p className="card-text"><b>CH {channel.channel + 1}</b>
                        <br />
                        <img src={channel.power ? bulb_on : bulb_off} className="card-icon" alt="LED state icon" />
                        {channel.power && (
                          <React.Fragment>
                            <br />
                            {channel.intensity}%
                          </React.Fragment>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_DIMMER' && this.state.device_data.productVariant === '2CH' && (
              <React.Fragment>
                <div className="row">
                  {this.state.device_data.data.status && this.state.device_data.data.status.map((channel, index) => (
                    <div key={index} className="text-center col-6 mt-1">
                      <p className="card-text"><b>CH {channel.channel + 1}</b>
                        <br />
                        <img src={channel.power ? bulb_on : bulb_off} className="card-icon" alt="LED state icon" />
                        {channel.power && (
                          <React.Fragment>
                            <br />
                            {channel.intensity}%
                          </React.Fragment>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}


            {this.state.device_data.productType === 'TH_DIMMER' && String(this.state.device_data.productVariant).trim() === '' && (
              <React.Fragment>
                {this.state.device_data.data && this.state.device_data.data.status.map((channel, index) => (
                  <div key={index} className="row">
                    <div className="text-center col-3 mt-0">
                      <img src={channel.power ? bulb_on : bulb_off} className="card-icon" alt="LED state icon" />
                    </div>
                    <div className="text-center col-6 mt-3" />
                    <div className="text-center col-3 mt-2">
                      {(this.state.device_data.productVariant !== 'PROJ-100' && channel.power) && (
                        <React.Fragment>
                          {channel.intensity}%
                        </React.Fragment>
                      )}
                      {(this.state.device_data.productVariant === 'PROJ-100' && channel.power) &&
                        proj_100_pattern.map((pattern_value, index) => {
                          if (pattern_value === (channel.pattern || 0)) {
                            return (
                              <React.Fragment>
                                {pattern_value === 0 ? 'Pattern Off' : `Pattern #${index}`}
                              </React.Fragment>
                            );
                          }
                          else {
                            return (<React.Fragment></React.Fragment>);
                          }
                        })}
                    </div>
                  </div>
                ))}
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_SWITCH' && this.state.device_data.productVariant === '4CH' && (
              <React.Fragment>
                <div className="row d-flex justify-content-between">
                  {this.state.device_data.data.status && this.state.device_data.data.status.map((channel, index) => (
                    <div key={index} className="text-center col-3 mt-1">
                      <p className="card-text"><b>CH {channel.channel + 1}</b>
                        <br />
                        <img src={channel.power ? switch_on : switch_off} className="card-icon" alt="Switch state icon" />
                      </p>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_SWITCH' && this.state.device_data.productVariant === '3CH' && (
              <React.Fragment>
                <div className="row d-flex justify-content-between">
                  {this.state.device_data.data.status && this.state.device_data.data.status.map((channel, index) => (
                    <div key={index} className="text-center col-4 mt-1">
                      <p className="card-text"><b>CH {channel.channel + 1}</b>
                        <br />
                        <img src={channel.power ? switch_on : switch_off} className="card-icon" alt="Switch state icon" />
                      </p>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_SWITCH' && this.state.device_data.productVariant === '2CH' && (
              <React.Fragment>
                <div className="row d-flex justify-content-between">
                  {this.state.device_data.data.status && this.state.device_data.data.status.map((channel, index) => (
                    <div key={index} className="text-center col-6 mt-1">
                      <p className="card-text"><b>CH {channel.channel + 1}</b>
                        <br />
                        <img src={channel.power ? switch_on : switch_off} className="card-icon" alt="Switch state icon" />
                      </p>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_SWITCH' && String(this.state.device_data.productVariant).trim() === '' && (
              <React.Fragment>
                {this.state.device_data.data && this.state.device_data.data.status.map((channel, index) => (
                  <div key={index} className="row d-flex justify-content-between">
                    <div className="text-center col-3 mt-0">
                      <img src={channel.power ? switch_on : switch_off} className="card-icon" alt="Switch state icon" />
                    </div>
                    <div className="text-center col-6 mt-3" />
                  </div>
                ))}
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_DATAGATEWAY' && (
              <React.Fragment>
                {this.state.device_data.data && this.state.device_data.data.map((datainfo, index) => (
                  <div key={index} className="row">
                    <div className="text-left col-5 mt-0">
                      <h2 className="mb-0">{datainfo.name}</h2>
                    </div>
                    <div className="text-right col-4 mt-1">
                      <h3 className="mb-0">{datainfo.value}</h3>
                    </div>
                    <div className="text-right col-3 mt-3">
                      <p className="card-text">{datainfo.unit}</p>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            )}

            {this.state.device_data.productType === 'TH_ALARM' && (
              <React.Fragment>
                <div className="row d-flex justify-content-between">
                  {this.state.device_data.data.status && this.state.device_data.data.status.map((channel, index) => (
                    <div key={index} className="text-center col-3 mt-1">
                      <p className="card-text"><b>{channel.name ? channel.name : 'Alarm #' + (channel.name + 1)}</b>
                        <br />
                        <img src={channel.state ? bell_on : bell_off} className="card-icon" alt="Alarm state icon" />
                      </p>
                    </div>
                  ))}


                  {/* Empty Tag for spacing */}
                  {this.state.device_data.data.status && this.state.device_data.data.status.length === 2 && (
                    <div className={"text-center col-3 mt-2"}>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}

          </div>
        </div>
      </React.Fragment >
    )
  }
}

SummaryCard.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceData: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(SummaryCard);
