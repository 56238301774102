import React from 'react';
import PropTypes from 'prop-types';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Collapse } from 'react-collapse';
import { toast } from 'react-toastify';
import ec_icon from '../images/cir-ec-color-active.png';
import ph_icon from '../images/cir-ph-color-active.png';
import temperature from '../images/temp-wat-active.png';

import apiHandler from '../api/axios-api';

class DoserController extends React.Component {
  constructor(props) {
    super(props);
    let setting_dict = {
      'is_alarm_available': false,
      'ec_enable': false,
      'ec_min': '',
      'ec_max': '',
      'ph_enable': false,
      'ph_min': '',
      'ph_max': '',
      'a_ml': '',
      'b_ml': '',
      'c_ml': '',
      'd_ml': '',
      'e_ml': '',
      'f_ml': '',
      'ph_ml': '',
      'ec_upper_al_enable': false,
      'ec_upper_al_value': '',
      'ec_lower_al_enable': false,
      'ec_lower_al_value': '',
      'ph_al_enable': false,
      'ph_upper_al_value': '',
      'ph_lower_al_value': '',
      'dosing_interval': 0,
      'init_water_volume': 50,
      'max_water_volume': 50,
    };
    if ('settings' in this.props.deviceData) {
      let settings = this.props.deviceData.settings || {};
      if ('setpoints' in settings) {
        settings.setpoints.forEach((setting) => {
          if (setting.parameter === 'EC') {
            setting_dict['ec_enable'] = setting.enable || false;
            setting_dict['ec_min'] = setting.min || '';
            setting_dict['ec_max'] = setting.max || '';
          }
          else if (setting.parameter === 'PH') {
            setting_dict['ph_enable'] = setting.enable || false;
            setting_dict['ph_min'] = setting.min || '';
            setting_dict['ph_max'] = setting.max || '';
          }
        });
      }
      if ('alarms' in settings) {
        settings.alarms.forEach((setting) => {
          if (setting.parameter === 'EC_UPPER') {
            setting_dict['ec_upper_al_enable'] = setting.enable || false;
            setting_dict['ec_upper_al_value'] = setting.value || '';
          }
          else if (setting.parameter === 'EC_LOWER') {
            setting_dict['ec_lower_al_enable'] = setting.enable || false;
            setting_dict['ec_lower_al_value'] = setting.value || '';
          }
          else if (setting.parameter === 'PH_UPPER') {
            setting_dict['ph_al_enable'] = setting.enable || false;
            setting_dict['ph_upper_al_value'] = setting.value || '';
          }
          else if (setting.parameter === 'PH_LOWER') {
            setting_dict['ph_al_enable'] = setting.enable || false;
            setting_dict['ph_lower_al_value'] = setting.value || '';
          }
        });
      }
      if ('advanced' in settings) {
        setting_dict['dosing_interval'] = settings.advanced.dosingInterval || 0;
        setting_dict['init_water_volume'] = settings.advanced.initWaterVolume || 50;
        setting_dict['max_water_volume'] = settings.advanced.maxWaterVolume || 50;
        setting_dict['nutrient_set'] = settings.advanced.nutrientSet;
        setting_dict['percentage_a'] = settings.advanced.percentageA || 100;
        setting_dict['percentage_b'] = settings.advanced.percentageB || 100;
        setting_dict['percentage_c'] = settings.advanced.percentageC || 100;
        setting_dict['percentage_d'] = settings.advanced.percentageD || 100;
        setting_dict['percentage_e'] = settings.advanced.percentageE || 100;
        setting_dict['percentage_f'] = settings.advanced.percentageF || 100;
      }
    }

    // check alarms available
    if (this.props.deviceData.data.alarms !== undefined) {
      setting_dict['is_alarm_available'] = true;
    }

    this.state = Object.assign({}, {
      device_id: this.props.deviceId,
      device_data: this.props.deviceData,
    }, setting_dict);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deviceData !== this.props.deviceData) {
      this.setState({
        device_data: this.props.deviceData
      });
    }
  }

  render() {
    const { classes } = this.props;

    // alarm card color
    let is_alarm = false;
    if (this.state.device_data.data) {
      if ((this.state.device_data.data.alarms ?? []).length > 0) {
        is_alarm = true;
      }
    }
    let card_color_class;
    if (this.state.device_data.data && this.state.device_data.online) {
      card_color_class = is_alarm ? classes.online_bgAlert : classes.online_bg;
    }
    else {
      card_color_class = is_alarm ? classes.offline_bgAlert : classes.offline_bg;
    }

    return (
      <React.Fragment>
        <div>
          <div className={`card mb-2 ${card_color_class}`}>
            <div className="card-body">
              <div className="row d-flex justify-content-between">
                <div className="text-center col-3 mt-2">
                <p className="card-text">
                    <img src={ec_icon} className="card-icon" alt="EC icon" />
                  </p>
                </div>
                <div className="text-center col-3 mt-2">
                <p className="card-text">
                    <img src={ph_icon} className="card-icon" alt="pH icon" />
                  </p>
                </div>
                <div className="text-center col-3 mt-0">
                  <p className="card-text">
                    <img src={temperature} className="card-icon" alt="Temperature icon" />
                  </p>
                </div>
              </div>
              <div className="row d-flex justify-content-between">
                <div className="text-center col-3 mt-1">
                  <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.ec) ? '-' : this.state.device_data.data.status.ec.toFixed(0)} μS/cm</p>
                </div>
                <div className="text-center col-3 mt-1">
                  <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.ph) ? '-' : this.state.device_data.data.status.ph.toFixed(2)}</p>
                </div>
                <div className="text-center col-3 mt-1">
                  <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.temperature) ? '-' : this.state.device_data.data.status.temperature.toFixed(1)} °C</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? '#e1f1e6' : 'lightgrey' }}>
            <div className="card-body">
              <div className="row">
                <h5 className="text-left col-12">EC</h5>
              </div>
              <div className="row">
                <div className="text-left col-6 mt-4">Enable Control</div>
                <div className="text-right col-6 mt-3">
                  <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state.ec_enable} onChange={
                    (checked) => {
                      this.setState({
                        ec_enable: checked
                      });
                      if (checked === false) {
                        apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                          setpoints: [
                            {
                              parameter: 'EC',
                              enable: checked,
                            }
                          ]
                        }).then((response) => {
                          toast.success("Success!");
                        }, (reason) => {
                          toast.error("Unable to perform your request");
                        });

                      }
                    }
                  } />
                </div>
              </div>
              <Collapse isOpened={this.state.ec_enable}>
                <div className="row">
                  <div className="text-left col-8 mt-4">EC Min (μS/cm)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-10000" value={this.state.ec_min} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          ec_min: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-8 mt-4">EC Max (μS/cm)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-10000" value={this.state.ec_max} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          ec_max: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-right col-12 mt-3">
                    <button type="button" className="btn btn-info" onClick={
                      (event) => {
                        if (this.state.ec_min !== '' && this.state.ec_max !== '') {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            setpoints: [
                              {
                                parameter: 'EC',
                                enable: this.state.ec_enable,
                                min: this.state.ec_min,
                                max: this.state.ec_max,
                              }
                            ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                        }
                      }
                    } >Apply EC Settings</button>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
          <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? '#e1f1e6' : 'lightgrey' }}>
            <div className="card-body">
              <div className="row">
                <h5 className="text-left col-12">pH</h5>
              </div>
              <div className="row">
                <div className="text-left col-6 mt-4">Enable Control</div>
                <div className="text-right col-6 mt-3">
                  <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state.ph_enable} onChange={
                    (checked) => {
                      this.setState({
                        ph_enable: checked
                      });
                      if (checked === false) {
                        apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                          setpoints: [
                            {
                              parameter: 'PH',
                              enable: checked,
                            }
                          ]
                        }).then((response) => {
                          toast.success("Success!");
                        }, (reason) => {
                          toast.error("Unable to perform your request");
                        });

                      }
                    }
                  } />
                </div>
              </div>
              <Collapse isOpened={this.state.ph_enable}>
                <div className="row">
                  <div className="text-left col-8 mt-4">pH Min</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-14" value={this.state.ph_min} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          ph_min: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-8 mt-4">pH Max</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-14" value={this.state.ph_max} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          ph_max: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-right col-12 mt-3">
                    <button type="button" className="btn btn-info" onClick={
                      (event) => {
                        if (this.state.ph_min !== '' && this.state.ph_max !== '') {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            setpoints: [
                              {
                                parameter: 'PH',
                                enable: this.state.ph_enable,
                                min: parseFloat(this.state.ph_min),
                                max: parseFloat(this.state.ph_max),
                              }
                            ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                        }
                      }
                    } >Apply pH Settings</button>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
          <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? '#e1f1e6' : 'lightgrey' }}>
            <div className="card-body">
              <div className="row">
                <h5 className="text-left col-12">Manual Dosing</h5>
              </div>
              <div className="row">
                <div className="text-left col-4 mt-4">Nutrient A</div>
                <div className="text-right col-4 mt-3">
                  <input type="number" className="form-control" placeholder="milliliter" value={this.state.a_ml} onChange={
                    (event) => {
                      this.setState({
                        a_ml: event.target.value
                      });
                    }
                  } />
                </div>
                <div className="text-right col-4 mt-3">
                  <button type="button" className="btn btn-info btn-block" onClick={
                    (event) => {
                      apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                        pumptest:
                          [
                            {
                              pump: 'A',
                              value: parseInt(this.state.a_ml) || 0,
                            }
                          ]
                      }).then((response) => {
                        toast.success("Success!");
                      }, (reason) => {
                        toast.error("Unable to perform your request");
                      });

                    }
                  }>Dose</button>
                </div>
              </div>
              <div className="row">
                <div className="text-left col-4 mt-4">Nutrient B</div>
                <div className="text-right col-4 mt-3">
                  <input type="number" className="form-control" placeholder="milliliter" value={this.state.b_ml} onChange={
                    (event) => {
                      this.setState({
                        b_ml: event.target.value
                      });
                    }
                  } />
                </div>
                <div className="text-right col-4 mt-3">
                  <button type="button" className="btn btn-info btn-block" onClick={
                    (event) => {
                      apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                        pumptest:
                          [
                            {
                              pump: 'B',
                              value: parseInt(this.state.b_ml) || 0,
                            }
                          ]
                      }).then((response) => {
                        toast.success("Success!");
                      }, (reason) => {
                        toast.error("Unable to perform your request");
                      });

                    }
                  }>Dose</button>
                </div>
              </div>
              {this.state['nutrient_set'] !== undefined && (
                <React.Fragment>
                  <div className="row">
                    <div className="text-left col-4 mt-4">Nutrient C</div>
                    <div className="text-right col-4 mt-3">
                      <input type="number" className="form-control" placeholder="milliliter" value={this.state.c_ml} onChange={
                        (event) => {
                          this.setState({
                            c_ml: event.target.value
                          });
                        }
                      } />
                    </div>
                    <div className="text-right col-4 mt-3">
                      <button type="button" className="btn btn-info btn-block" onClick={
                        (event) => {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            pumptest:
                              [
                                {
                                  pump: 'C',
                                  value: parseInt(this.state.c_ml) || 0,
                                }
                              ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                        }
                      }>Dose</button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-4 mt-4">Nutrient D</div>
                    <div className="text-right col-4 mt-3">
                      <input type="number" className="form-control" placeholder="milliliter" value={this.state.d_ml} onChange={
                        (event) => {
                          this.setState({
                            d_ml: event.target.value
                          });
                        }
                      } />
                    </div>
                    <div className="text-right col-4 mt-3">
                      <button type="button" className="btn btn-info btn-block" onClick={
                        (event) => {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            pumptest:
                              [
                                {
                                  pump: 'D',
                                  value: parseInt(this.state.d_ml) || 0,
                                }
                              ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                        }
                      }>Dose</button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-4 mt-4">Nutrient E</div>
                    <div className="text-right col-4 mt-3">
                      <input type="number" className="form-control" placeholder="milliliter" value={this.state.e_ml} onChange={
                        (event) => {
                          this.setState({
                            e_ml: event.target.value
                          });
                        }
                      } />
                    </div>
                    <div className="text-right col-4 mt-3">
                      <button type="button" className="btn btn-info btn-block" onClick={
                        (event) => {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            pumptest:
                              [
                                {
                                  pump: 'E',
                                  value: parseInt(this.state.e_ml) || 0,
                                }
                              ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                        }
                      }>Dose</button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-4 mt-4">Nutrient F</div>
                    <div className="text-right col-4 mt-3">
                      <input type="number" className="form-control" placeholder="milliliter" value={this.state.f_ml} onChange={
                        (event) => {
                          this.setState({
                            f_ml: event.target.value
                          });
                        }
                      } />
                    </div>
                    <div className="text-right col-4 mt-3">
                      <button type="button" className="btn btn-info btn-block" onClick={
                        (event) => {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            pumptest:
                              [
                                {
                                  pump: 'F',
                                  value: parseInt(this.state.f_ml) || 0,
                                }
                              ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                        }
                      }>Dose</button>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="row">
                <div className="text-left col-4 mt-4">pH Down</div>
                <div className="text-right col-4 mt-3">
                  <input type="number" className="form-control" placeholder="milliliter" value={this.state.ph_ml} onChange={
                    (event) => {
                      this.setState({
                        ph_ml: event.target.value
                      });
                    }
                  } />
                </div>
                <div className="text-right col-4 mt-3">
                  <button type="button" className="btn btn-info btn-block" onClick={
                    (event) => {
                      apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                        pumptest:
                          [
                            {
                              pump: 'PH',
                              value: parseInt(this.state.ph_ml) || 0,
                            }
                          ]
                      }).then((response) => {
                        toast.success("Success!");
                      }, (reason) => {
                        toast.error("Unable to perform your request");
                      });

                    }
                  }>Dose</button>
                </div>
              </div>
            </div>
          </div>

          {this.state.is_alarm_available && (<React.Fragment>
            <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'moccasin' : 'lightgrey' }}>
              <div className="card-body">
                <div className="row">
                  <h5 className="text-left col-12">EC Upper Alarm</h5>
                </div>
                <div className="row">
                  <div className="text-left col-6 mt-4">Enable</div>
                  <div className="text-right col-6 mt-3">
                    <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state.ec_upper_al_enable} onChange={
                      (checked) => {
                        this.setState({
                          ec_upper_al_enable: checked
                        });
                        if (this.state.ec_upper_al_value !== '') {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            alarms:
                              [
                                {
                                  parameter: 'EC_UPPER',
                                  enable: checked,
                                  value: this.state.ec_upper_al_value,
                                }
                              ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });
                        }
                      }
                    } />
                  </div>
                </div>
                <Collapse isOpened={this.state.ec_upper_al_enable}>
                  <div className="row">
                    <div className="text-left col-8 mt-4">Upper Level (μS/cm)</div>
                    <div className="text-right col-4 mt-3">
                      <input type="text" className="form-control" placeholder="0-10000" value={this.state.ec_upper_al_value} maxLength={5} onChange={
                        (event) => {
                          this.setState({
                            ec_upper_al_value: event.target.value
                          });
                        }
                      } />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-right col-12 mt-3">
                      <button type="button" className="btn btn-info" onClick={
                        (event) => {
                          if (this.state.ec_upper_al_value !== '') {
                            apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                              alarms:
                                [
                                  {
                                    parameter: 'EC_UPPER',
                                    enable: this.state.ec_upper_al_enable,
                                    value: this.state.ec_upper_al_value,
                                  }
                                ]
                            }).then((response) => {
                              toast.success("Success!");
                            }, (reason) => {
                              toast.error("Unable to perform your request");
                            });
                          }
                        }
                      } >Apply Settings</button>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>

            <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'moccasin' : 'lightgrey' }}>
              <div className="card-body">
                <div className="row">
                  <h5 className="text-left col-12">EC Lower Alarm</h5>
                </div>
                <div className="row">
                  <div className="text-left col-6 mt-4">Enable</div>
                  <div className="text-right col-6 mt-3">
                    <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state.ec_lower_al_enable} onChange={
                      (checked) => {
                        this.setState({
                          ec_lower_al_enable: checked
                        });
                        if (this.state.ec_lower_al_value !== '') {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            alarms:
                              [
                                {
                                  parameter: 'EC_LOWER',
                                  enable: checked,
                                  value: this.state.ec_lower_al_value,
                                }
                              ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });
                        }
                      }
                    } />
                  </div>
                </div>
                <Collapse isOpened={this.state.ec_lower_al_enable}>
                  <div className="row">
                    <div className="text-left col-8 mt-4">Lower Level (μS/cm)</div>
                    <div className="text-right col-4 mt-3">
                      <input type="text" className="form-control" placeholder="0-10000" value={this.state.ec_lower_al_value} maxLength={5} onChange={
                        (event) => {
                          this.setState({
                            ec_lower_al_value: event.target.value
                          });
                        }
                      } />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-right col-12 mt-3">
                      <button type="button" className="btn btn-info" onClick={
                        (event) => {
                          if (this.state.ec_lower_al_value !== '') {
                            apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                              alarms:
                                [
                                  {
                                    parameter: 'EC_LOWER',
                                    enable: this.state.ec_lower_al_enable,
                                    value: this.state.ec_lower_al_value,
                                  }
                                ]
                            }).then((response) => {
                              toast.success("Success!");
                            }, (reason) => {
                              toast.error("Unable to perform your request");
                            });
                          }
                        }
                      } >Apply Settings</button>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>

            <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'moccasin' : 'lightgrey' }}>
              <div className="card-body">
                <div className="row">
                  <h5 className="text-left col-12">pH Alarm</h5>
                </div>
                <div className="row">
                  <div className="text-left col-6 mt-4">Enable</div>
                  <div className="text-right col-6 mt-3">
                    <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state.ph_al_enable} onChange={
                      (checked) => {
                        this.setState({
                          ph_al_enable: checked
                        });
                        if (this.state.ph_lower_al_value !== '' && this.state.ph_upper_al_value !== '') {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            alarms:
                              [
                                {
                                  parameter: 'PH_UPPER',
                                  enable: checked,
                                  value: this.state.ph_upper_al_value,
                                },
                                {
                                  parameter: 'PH_LOWER',
                                  enable: checked,
                                  value: this.state.ph_lower_al_value,
                                }
                              ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                        }
                      }
                    } />
                  </div>
                </div>
                <Collapse isOpened={this.state.ph_al_enable}>
                  <div className="row">
                    <div className="text-left col-8 mt-4">Low Level (Unit)</div>
                    <div className="text-right col-4 mt-3">
                      <input type="text" className="form-control" placeholder="0-10000" value={this.state.ph_lower_al_value} maxLength={5} onChange={
                        (event) => {
                          this.setState({
                            ph_lower_al_value: event.target.value
                          });
                        }
                      } />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-8 mt-4">High Level (Unit)</div>
                    <div className="text-right col-4 mt-3">
                      <input type="text" className="form-control" placeholder="0-10000" value={this.state.ph_upper_al_value} maxLength={5} onChange={
                        (event) => {
                          this.setState({
                            ph_upper_al_value: event.target.value
                          });
                        }
                      } />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-right col-12 mt-3">
                      <button type="button" className="btn btn-info" onClick={
                        (event) => {
                          if (this.state.ph_lower_al_value !== '' && this.state.ph_upper_al_value !== '') {
                            apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                              alarms:
                                [
                                  {
                                    parameter: 'PH_UPPER',
                                    enable: this.state.ph_al_enable,
                                    value: this.state.ph_upper_al_value,
                                  },
                                  {
                                    parameter: 'PH_LOWER',
                                    enable: this.state.ph_al_enable,
                                    value: this.state.ph_lower_al_value,
                                  }
                                ]
                            }).then((response) => {
                              toast.success("Success!");
                            }, (reason) => {
                              toast.error("Unable to perform your request");
                            });

                          }
                        }
                      } >Apply Settings</button>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </React.Fragment>)}

          <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'darkred' : 'maroon' }}>
            <div className="card-body">
              <div className="row">
                <h5 className="text-left col-12 text-white">Advanced Setting</h5>
              </div>
              <div className="row">
                <div className="text-left text-white col-6 mt-4">Dosing Interval</div>
                <div className="text-left col-6 mt-3">
                  <select className="btn btn-warning form-control" value={this.state.dosing_interval} onChange={
                    (event) => {
                      this.setState({
                        dosing_interval: event.target.value
                      });
                    }
                  }>
                    <option value={0}>Automatic</option>
                    <option value={600000}>10 Minutes</option>
                    <option value={1800000}>30 Minutes</option>
                    <option value={3600000}>Every Hour</option>
                    <option value={7200000}>2 Hours</option>
                    <option value={14400000}>4 Hours</option>
                    <option value={21600000}>6 Hours</option>
                    <option value={43200000}>12 Hours</option>
                    <option value={86400000}>24 Hours</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="text-left text-white col-6 mt-4">Water Volume</div>
                <div className="text-left col-6 mt-3">
                  <select className="btn btn-warning form-control" value={this.state.init_water_volume} onChange={
                    (event) => {
                      this.setState({
                        init_water_volume: event.target.value,
                        max_water_volume: event.target.value,
                      });
                    }
                  }>
                    <option value={20}>Smallest (~20L.)</option>
                    <option value={50}>Small (~50L.)</option>
                    <option value={100}>Medium (~100L.)</option>
                    <option value={200}>Large (~200L.)</option>
                    <option value={500}>Larger (~500L.)</option>
                    <option value={2000}>Extra Large (2000L.)</option>
                    <option value={5000}>Industrial Size (5000+L.)</option>
                  </select>
                </div>
              </div>

              {this.state['nutrient_set'] !== undefined && (
                <div className="row">
                  <div className="text-left text-white col-8 mt-4">A Percentage (0%-100%)</div>
                  <div className="text-right col-4 mt-3 ">
                    <input type="text" className="form-control" placeholder="0-100" value={this.state.percentage_a} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          percentage_a: event.target.value
                        });
                      }
                    } />
                  </div>
                  <div className="text-left text-white col-8 mt-4">B Percentage (0%-100%)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-100" value={this.state.percentage_b} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          percentage_b: event.target.value
                        });
                      }
                    } />
                  </div>
                  <div className="text-left text-white col-8 mt-4">C Percentage (0%-100%)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-100" value={this.state.percentage_c} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          percentage_c: event.target.value
                        });
                      }
                    } />
                  </div>
                  <div className="text-left text-white col-8 mt-4">D Percentage (0%-100%)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-100" value={this.state.percentage_d} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          percentage_d: event.target.value
                        });
                      }
                    } />
                  </div>
                  <div className="text-left text-white col-8 mt-4">E Percentage (0%-100%)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-100" value={this.state.percentage_e} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          percentage_e: event.target.value
                        });
                      }
                    } />
                  </div>
                  <div className="text-left text-white col-8 mt-4">F Percentage (0%-100%)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-100" value={this.state.percentage_f} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          percentage_f: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
              )}

              <div className="row">
                <div className="text-right col-12 mt-3">
                  <button type="button" className="btn btn-warning" onClick={
                    (event) => {
                      let advanced_dict = {
                        dosingInterval: this.state.dosing_interval,
                        initWaterVolume: this.state.init_water_volume,
                        maxWaterVolume: this.state.max_water_volume,
                      }
                      if (this.state['nutrient_set'] !== undefined) {
                        advanced_dict = Object.assign({}, advanced_dict, {
                          // merge dict
                          percentageA: parseFloat(this.state.percentage_a),
                          percentageB: parseFloat(this.state.percentage_b),
                          percentageC: parseFloat(this.state.percentage_c),
                          percentageD: parseFloat(this.state.percentage_d),
                          percentageE: parseFloat(this.state.percentage_e),
                          percentageF: parseFloat(this.state.percentage_f),
                        });
                      }
                      apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                        advanced: advanced_dict,
                      }).then((response) => {
                        toast.success("Success!");
                      }, (reason) => {
                        toast.error("Unable to perform your request");
                      });

                    }
                  } >Apply Advanced Setting</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment >
    )
  }
}

DoserController.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceData: PropTypes.object.isRequired,
  deviceHistorical: PropTypes.object,
  classes: PropTypes.object,
};

export default DoserController;
