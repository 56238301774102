import React from 'react';
import PropTypes from 'prop-types';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Collapse } from "react-collapse";
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import switch_on from '../images/active-on.png';
import switch_off from '../images/active-off.png';
import loading_indicator from '../images/loading-indicator.gif';

import apiHandler from '../api/axios-api';

class SwitchController extends React.Component {
  constructor(props) {
    super(props);
    let _settings = [];
    let setting_dict = {};

    if ('settings' in this.props.deviceData) {
      _settings = this.props.deviceData.settings;
      _settings || (_settings = []);

      this.props.deviceData.data.status.forEach((channel) => {
        setting_dict[`power_${channel.channel}`] = channel.power || false;
        setting_dict[`power_man_${channel.channel}`] = channel.power || false;
        setting_dict[`power_request_${channel.channel}`] = false;
        setting_dict[`interval_${channel.channel}`] = channel.interval;
        setting_dict[`prohibited_clear_request_${channel.channel}`] = false;
      });
      this.props.deviceData.settings.forEach((setting) => {
        // schedule
        let api_schedule = setting.schedule || [];
        let render_schedule = [];
        api_schedule.forEach((schedule) => {
          let api_time = parseInt(schedule.time);
          if (isNaN(api_time)) {
            api_time = 80000;
          }
          let render_time = new Date();
          render_time.setHours((api_time / 10000) % 100);
          render_time.setMinutes((api_time / 100) % 100);
          render_time.setSeconds(0);
          render_schedule.push({
            time: render_time,
            action: schedule.power ? 'on' : 'off',
            isEnabled: schedule.enable,
          });
        });
        setting_dict[`schedule_${setting.channel}`] = render_schedule;
        // interval
        // default value
        setting_dict[`on_duration_hour_${setting.channel}`] = 0;
        setting_dict[`on_duration_min_${setting.channel}`] = 0;
        setting_dict[`on_duration_sec_${setting.channel}`] = 15;
        setting_dict[`off_duration_hour_${setting.channel}`] = 0;
        setting_dict[`off_duration_min_${setting.channel}`] = 0;
        setting_dict[`off_duration_sec_${setting.channel}`] = 15;
        let api_interval = setting.interval || [];
        api_interval.forEach((schedule) => {
          // we only expect one schedule at this time, the last one will be presented
          let api_on_interval = parseInt(schedule.onPeriod);
          let api_off_interval = parseInt(schedule.offPeriod);
          setting_dict[`on_duration_hour_${setting.channel}`] = Math.floor(api_on_interval / 3600);
          setting_dict[`on_duration_min_${setting.channel}`] = Math.floor(api_on_interval / 60) % 60;
          setting_dict[`on_duration_sec_${setting.channel}`] = api_on_interval % 60;
          setting_dict[`off_duration_hour_${setting.channel}`] = Math.floor(api_off_interval / 3600);
          setting_dict[`off_duration_min_${setting.channel}`] = Math.floor(api_off_interval / 60) % 60;
          setting_dict[`off_duration_sec_${setting.channel}`] = api_off_interval % 60;
        });
      });
    }

    this.state = Object.assign({}, {
      device_id: this.props.deviceId,
      device_data: this.getDeviceData(),
      selected_channel: '0',
      dialog_add_schedule_visible: false,
      dialog_add_schedule_time: new Date(),
      dialog_add_schedule_action: 'off',
    }, setting_dict);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.deviceData) !== JSON.stringify(this.props.deviceData)) {
      let setting_dict = {};
      this.props.deviceData.data.status.forEach((channel) => {
        setting_dict[`power_${channel.channel}`] = channel.power || false;
        if (this.state[`power_request_${channel.channel}`]) {
          if (setting_dict[`power_${channel.channel}`] === this.state[`power_man_${channel.channel}`]) {
            setting_dict[`power_request_${channel.channel}`] = false;
            setting_dict[`power_man_${channel.channel}`] = setting_dict[`power_${channel.channel}`];
          }
        }
        else {
          setting_dict[`power_man_${channel.channel}`] = setting_dict[`power_${channel.channel}`];
        }
        if (this.state[`prohibited_clear_request_${channel.channel}`]) {
          // clear request when state is cleared
          if (channel.prohibited === false) {
            setting_dict[`prohibited_clear_request_${channel.channel}`] = false;
          }
        }
      });
      let nextState = Object.assign({}, {
        device_data: this.props.deviceData,
      }, setting_dict);
      this.setState(nextState);
    }
  }

  getDeviceData() {
    let _deviceData = this.props.deviceData;

    if (!_deviceData.data) {
      _deviceData.data = { status: [] };
    }

    return _deviceData;
  }

  updateChannelSchedule(channel) {
    let scheduleMessage = [];
    let render_schedules = this.state[`schedule_${channel}`] || [];
    render_schedules.forEach((schedule) => {
      let render_time = schedule.time;
      let api_time = (render_time.getHours() * 10000) + (render_time.getMinutes() * 100);
      scheduleMessage.push({
        time: api_time,
        power: schedule.action === 'on' ? true : false,
        enable: schedule.isEnabled,
      });
    });
    let updateMessage = [{
      channel: channel,
      schedule: scheduleMessage,
    }];
    apiHandler.patch('devices/' + this.props.deviceId + '/settings', updateMessage).then((response) => {
      toast.success("Success!");
    }, (reason) => {
      toast.error("Unable to perform your request");
    });

  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? '#e1f1e6' : 'lightgrey' }}>
          <div className="card-body" style={{ paddingTop: 0 }}>
            <div className="row">
              {/* {this.state.device_data.productVariant === '4CH' && ( */}
              {(
                <React.Fragment>
                  <Box sx={{ width: '100%' }}>
                    <TabContext value={this.state.selected_channel}>
                      <Box sx={{ borderBottom: 0, borderTop: 0 }}>
                        <TabList aria-label="Channels" onChange={(event, value) => {
                          let updated_dict = {};
                          updated_dict['selected_channel'] = value;
                          this.setState(updated_dict);
                        }}
                        >
                          {this.state.device_data.data && this.state.device_data.data.status.map((channel, index) => {
                            let card_color_class;
                            if (this.state.device_data.data && this.state.device_data.online) {
                              card_color_class = channel.prohibited ? classes.online_bgAlert : classes.online_bg;
                            }
                            else {
                              card_color_class = channel.prohibited ? classes.offline_bgAlert : classes.offline_bg;
                            }
                            return (
                              <Tab key={index} style={{ minWidth: '25%', maxWidth: '25%' }} label={(
                                <div className={card_color_class}>
                                  <p className="card-text"><b>SW {channel.channel + 1}</b>
                                    <br />
                                    <img src={channel.power ? switch_on : switch_off} width="80%" alt="Switch state icon" />
                                  </p>
                                </div>
                              )} value={channel.channel.toString()} />
                            )
                          })}
                        </TabList>
                      </Box>
                      {this.state.device_data.data && this.state.device_data.data.status.map((channel, index) => (
                        <TabPanel key={index} value={channel.channel.toString()} style={{ paddingLeft: 16, paddingRight: 16 }}>
                          <div className="row">
                            <h5 className="text-left col-12 mb-3">Switch {channel.channel + 1}</h5>
                          </div>
                          <div className="row">

                            {channel.prohibited === true &&
                              <React.Fragment>
                                <div className="text-left col-6 mt-2 mb-3 text-danger">Switch is Prohibited</div>
                                <div className="text-right col-6 mt-0">
                                  {((channel.prohibited === true) && (this.state['prohibited_clear_request_' + channel.channel] === false)) ?
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      disabled={channel.sourceProhibited === true}
                                      onClick={(event) => {
                                        let updated_dict = {};
                                        apiHandler.delete('devices/' + this.props.deviceId + `/alarms/CH${channel.channel}_PROHIBITED`).then((response) => {
                                          updated_dict['prohibited_clear_request_' + channel.channel] = true;
                                          this.setState(updated_dict);
                                          toast.success("Success!");
                                        }, (reason) => {
                                          toast.error("Unable to perform your request");
                                        });
                                      }}
                                    >
                                      Clear Prohibited
                                    </button>
                                    :
                                    <div className="mt-2">
                                      <img src={loading_indicator} alt="Processing request" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                  }
                                </div>
                              </React.Fragment>
                            }

                            {channel.sourceProhibited !== undefined &&
                              <React.Fragment>
                                <div className="text-left col-6 mt-2 mb-3">Source Status</div>
                                <div className="text-right col-6 mt-2">
                                  {channel.sourceProhibited === true ? 'Prohibited' : 'Normal'}
                                </div>
                              </React.Fragment>
                            }

                            <div className="text-left col-6 mt-2 mb-3">Power</div>
                            <div className="text-right col-6">
                              {this.state['power_' + channel.channel] === this.state['power_man_' + channel.channel] ?
                                <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state['power_' + channel.channel]} onChange={
                                  (checked) => {
                                    let updated_dict = {};
                                    updated_dict['power_man_' + channel.channel] = checked;
                                    updated_dict['power_request_' + channel.channel] = true;
                                    this.setState(updated_dict);
                                    apiHandler.patch('devices/' + this.props.deviceId + '/settings', [
                                      {
                                        channel: parseInt(channel.channel).toString(),
                                        power: checked,
                                      }
                                    ]).then((response) => {
                                      toast.success("Success!");
                                    }, (reason) => {
                                      toast.error("Unable to perform your request");
                                    });

                                  }
                                } />
                                :
                                <div className="mt-2">
                                  <img src={loading_indicator} alt="Processing request" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                              }
                            </div>
                          </div>

                          {this.state['interval_' + channel.channel] !== undefined && (
                            <div className="row">
                              <div className="text-left col-6 mt-2 mb-3">Auto Toggle</div>
                              <div className="text-right col-6">
                                <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state['interval_' + channel.channel]} onChange={
                                  (checked) => {
                                    let updated_dict = {};
                                    updated_dict['interval_' + channel.channel] = checked;
                                    this.setState(updated_dict);
                                    if (checked === false) {
                                      let onPeriod =
                                        (this.state['on_duration_hour_' + channel.channel] * 3600) +
                                        (this.state['on_duration_min_' + channel.channel] * 60) +
                                        (this.state['on_duration_sec_' + channel.channel]);
                                      let offPeriod =
                                        (this.state['off_duration_hour_' + channel.channel] * 3600) +
                                        (this.state['off_duration_min_' + channel.channel] * 60) +
                                        (this.state['off_duration_sec_' + channel.channel]);
                                      apiHandler.patch('devices/' + this.props.deviceId + '/settings', [
                                        {
                                          channel: parseInt(channel.channel).toString(),
                                          interval: [
                                            {
                                              enable: false,
                                              onPeriod: onPeriod,
                                              offPeriod: offPeriod,
                                            }
                                          ],
                                        }
                                      ]).then((response) => {
                                        toast.success("Success!");
                                      }, (reason) => {
                                        toast.error("Unable to perform your request");
                                      });
                                    }
                                  }
                                } />
                              </div>
                            </div>
                          )}

                          <Collapse isOpened={this.state['interval_' + channel.channel] !== undefined && this.state['interval_' + channel.channel] === true}>
                            <div className="row mb-2">
                              <div className="text-left col-4 mt-2">On Period</div>
                              <div className="text-right col-8 mt-1">
                                <Select
                                  fullWidth={false}
                                  native={true}
                                  variant="standard"
                                  value={this.state['on_duration_hour_' + channel.channel]}
                                  onChange={
                                    (event) => {
                                      let updated_dict = {};
                                      updated_dict['on_duration_hour_' + channel.channel] = parseInt(event.target.value);
                                      this.setState(updated_dict);
                                    }
                                  }>
                                  {[...Array(48).keys()].map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                  ))}
                                </Select>
                                H
                                &nbsp;&nbsp;
                                <Select
                                  fullWidth={false}
                                  native={true}
                                  variant="standard"
                                  value={this.state['on_duration_min_' + channel.channel]}
                                  onChange={
                                    (event) => {
                                      let updated_dict = {};
                                      updated_dict['on_duration_min_' + channel.channel] = parseInt(event.target.value);
                                      this.setState(updated_dict);
                                    }
                                  }>
                                  {[...Array(60).keys()].map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                  ))}
                                </Select>
                                M
                                &nbsp;&nbsp;
                                <Select
                                  fullWidth={false}
                                  native={true}
                                  variant="standard"
                                  value={this.state['on_duration_sec_' + channel.channel]}
                                  onChange={
                                    (event) => {
                                      let updated_dict = {};
                                      updated_dict['on_duration_sec_' + channel.channel] = parseInt(event.target.value);
                                      this.setState(updated_dict);
                                    }
                                  }>
                                  {[...Array(60).keys()].map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                  ))}
                                </Select>
                                S
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="text-left col-4 mt-2">Off Period</div>
                              <div className="text-right col-8 mt-1">
                                <Select
                                  fullWidth={false}
                                  native={true}
                                  variant="standard"
                                  value={this.state['off_duration_hour_' + channel.channel]}
                                  onChange={
                                    (event) => {
                                      let updated_dict = {};
                                      updated_dict['off_duration_hour_' + channel.channel] = parseInt(event.target.value);
                                      this.setState(updated_dict);
                                    }
                                  }>
                                  {[...Array(48).keys()].map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                  ))}
                                </Select>
                                H
                                &nbsp;&nbsp;
                                <Select
                                  fullWidth={false}
                                  native={true}
                                  variant="standard"
                                  value={this.state['off_duration_min_' + channel.channel]}
                                  onChange={
                                    (event) => {
                                      let updated_dict = {};
                                      updated_dict['off_duration_min_' + channel.channel] = parseInt(event.target.value);
                                      this.setState(updated_dict);
                                    }
                                  }>
                                  {[...Array(60).keys()].map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                  ))}
                                </Select>
                                M
                                &nbsp;&nbsp;
                                <Select
                                  fullWidth={false}
                                  native={true}
                                  variant="standard"
                                  value={this.state['off_duration_sec_' + channel.channel]}
                                  onChange={
                                    (event) => {
                                      let updated_dict = {};
                                      updated_dict['off_duration_sec_' + channel.channel] = parseInt(event.target.value);
                                      this.setState(updated_dict);
                                    }
                                  }>
                                  {[...Array(60).keys()].map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                  ))}
                                </Select>
                                S
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="text-right col-12 mt-3">
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  onClick={(event) => {
                                    let onPeriod =
                                      (this.state['on_duration_hour_' + channel.channel] * 3600) +
                                      (this.state['on_duration_min_' + channel.channel] * 60) +
                                      (this.state['on_duration_sec_' + channel.channel]);
                                    let offPeriod =
                                      (this.state['off_duration_hour_' + channel.channel] * 3600) +
                                      (this.state['off_duration_min_' + channel.channel] * 60) +
                                      (this.state['off_duration_sec_' + channel.channel]);
                                    apiHandler.patch('devices/' + this.props.deviceId + '/settings', [
                                      {
                                        channel: parseInt(channel.channel).toString(),
                                        interval: [
                                          {
                                            enable: true,
                                            onPeriod: onPeriod,
                                            offPeriod: offPeriod,
                                          }
                                        ],
                                      }
                                    ]).then((response) => {
                                      toast.success("Success!");
                                    }, (reason) => {
                                      toast.error("Unable to perform your request");
                                    });
                                  }}
                                >
                                  Apply Settings
                                </button>
                              </div>
                            </div>
                          </Collapse>

                          <Collapse isOpened={(this.state['interval_' + channel.channel] !== undefined && this.state['interval_' + channel.channel] === false) || (this.state['interval_' + channel.channel] === undefined)}>
                            <div className="row mb-2">
                              <div className="text-left col-6 mt-2">Schedule</div>
                              <div className="text-right col-6 mt-1">
                                <button type="button" className="btn btn-warning btn-sm" onClick={
                                  (event) => {
                                    this.setState({
                                      dialog_add_schedule_visible: true,
                                    });
                                  }
                                } >
                                  <FontAwesomeIcon icon={faPlus} /> Add
                                </button>
                                <Dialog
                                  maxWidth={false}
                                  fullWidth={true}
                                  open={this.state.dialog_add_schedule_visible}
                                  onClose={
                                    (event) => {
                                      this.setState({
                                        dialog_add_schedule_visible: false,
                                      });
                                    }}>
                                  <DialogTitle>Schedule for SW {channel.channel + 1}</DialogTitle>
                                  <DialogContent>
                                    <div className="row">
                                      <div className="text-left col-6 mt-4">Time</div>
                                      <div className="text-right col-6 mt-3">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <TimePicker
                                            fullWidth={true}
                                            label={''}
                                            value={this.state.dialog_add_schedule_time}
                                            onChange={(newValue) => {
                                              this.setState({
                                                dialog_add_schedule_time: newValue,
                                              });
                                            }}
                                            onAccept={(acceptValue) => {
                                              // do nothing
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="standard" />}
                                          />
                                        </LocalizationProvider>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="text-left col-6 mt-4">Power</div>
                                      <div className="text-right col-6 mt-3">
                                        <Select
                                          fullWidth={true}
                                          native={true}
                                          variant="standard"
                                          value={this.state.dialog_add_schedule_action}
                                          onChange={
                                            (event) => {
                                              this.setState({
                                                dialog_add_schedule_action: event.target.value,
                                              });
                                            }
                                          }>
                                          <option value={'on'}>ON</option>
                                          <option value={'off'}>OFF</option>
                                        </Select>
                                      </div>
                                    </div>
                                  </DialogContent>
                                  <DialogActions>
                                    <button type="button" className="btn" onClick={
                                      (event) => {
                                        this.setState({
                                          dialog_add_schedule_visible: false,
                                        });
                                      }
                                    } >
                                      Cancel
                                    </button>
                                    <button type="button" className="btn btn-info" onClick={
                                      (event) => {
                                        let updated_dict = {};
                                        let channel_schedule_list = this.state[`schedule_${channel.channel}`];
                                        console.log("org", channel_schedule_list);
                                        channel_schedule_list.push(
                                          {
                                            time: this.state.dialog_add_schedule_time,
                                            action: this.state.dialog_add_schedule_action,
                                            isNew: true,
                                            isEnabled: true,
                                          }
                                        );
                                        console.log("new", channel_schedule_list);
                                        updated_dict[`schedule_${channel.channel}`] = channel_schedule_list;
                                        this.setState(updated_dict);
                                        this.setState({
                                          dialog_add_schedule_visible: false,
                                        });
                                        setTimeout(() => {
                                          this.updateChannelSchedule.bind(this)(channel.channel);
                                        }, 100);
                                      }
                                    } >
                                      <FontAwesomeIcon icon={faPlus} /> Add
                                    </button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div>

                            {this.state[`schedule_${channel.channel}`].length > 0 ? (
                              <div className="row" >
                                <div className="text-left col-4 text-secondary">
                                  Time
                                </div>
                                <div className="text-left col-3 text-secondary">
                                  Power
                                </div>
                                <div className="text-left col-5 text-secondary">
                                  Enable
                                </div>
                              </div>
                            ) : (
                              <div className="row">
                                <div className="text-center col-12 mt-4 text-secondary">
                                  Try to add your first schedule
                                </div>
                              </div>
                            )}

                            {this.state[`schedule_${channel.channel}`].map((schedule, index) => (
                              <div key={index} className="row">
                                <div className="text-left col-4 mt-1">
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                      fullWidth={true}
                                      label={''}
                                      value={schedule.time}
                                      onOpen={() => {
                                        this.setState({
                                          popup_time: schedule.time,
                                        });
                                      }}
                                      onChange={(newValue) => {
                                        this.setState({
                                          popup_time: newValue,
                                        });
                                      }}
                                      onAccept={(newValue) => {
                                        let updated_dict = {};
                                        let channel_schedule_list = this.state[`schedule_${channel.channel}`];
                                        channel_schedule_list[index]['time'] = newValue;
                                        updated_dict[`schedule_${channel.channel}`] = channel_schedule_list;
                                        this.setState(updated_dict);
                                        setTimeout(() => {
                                          this.updateChannelSchedule.bind(this)(channel.channel);
                                        }, 100);
                                      }}
                                      renderInput={(params) => <TextField {...params} variant="standard" />}
                                    />
                                  </LocalizationProvider>
                                </div>
                                <div className="text-left col-3 mt-1">
                                  <Select
                                    fullWidth={true}
                                    native={true}
                                    variant="standard"
                                    value={schedule.action}
                                    onChange={
                                      (event) => {
                                        let updated_dict = {};
                                        let channel_schedule_list = this.state[`schedule_${channel.channel}`];
                                        channel_schedule_list[index]['action'] = event.target.value;
                                        updated_dict[`schedule_${channel.channel}`] = channel_schedule_list;
                                        this.setState(updated_dict);
                                        setTimeout(() => {
                                          this.updateChannelSchedule.bind(this)(channel.channel);
                                        }, 100);
                                      }
                                    }>
                                    <option value={'on'}>ON</option>
                                    <option value={'off'}>OFF</option>
                                  </Select>

                                </div>
                                <div className="text-right col-2 mt-1">
                                  <BootstrapSwitchButton size="sm" onlabel="Enabled" offlabel="Disabled" onstyle="success" offstyle="secondary" width={60} checked={schedule.isEnabled} onChange={
                                    (checked) => {
                                      let updated_dict = {};
                                      let channel_schedule_list = this.state[`schedule_${channel.channel}`];
                                      channel_schedule_list[index]['isEnabled'] = checked;
                                      updated_dict[`schedule_${channel.channel}`] = channel_schedule_list;
                                      this.setState(updated_dict);
                                      setTimeout(() => {
                                        this.updateChannelSchedule.bind(this)(channel.channel);
                                      }, 100);
                                    }
                                  } />
                                </div>
                                <div className="text-right col-3 mt-1">
                                  <button type="button" className="btn btn-warning btn-sm" onClick={
                                    (event) => {
                                      if (window.confirm('Are you sure want to remove?')) {
                                        let updated_dict = {};
                                        let channel_schedule_list = this.state[`schedule_${channel.channel}`];
                                        channel_schedule_list.splice(index, 1);
                                        updated_dict[`schedule_${channel.channel}`] = channel_schedule_list;
                                        this.setState(updated_dict);
                                        setTimeout(() => {
                                          this.updateChannelSchedule.bind(this)(channel.channel);
                                        }, 100);
                                      }
                                    }
                                  } >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </div>
                              </div>
                            ))}
                          </Collapse>
                        </TabPanel>
                      ))}
                    </TabContext>
                  </Box>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </React.Fragment >
    )
  }
}

SwitchController.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceData: PropTypes.object.isRequired,
};

export default SwitchController;
