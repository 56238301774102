import React from 'react';
import PropTypes from 'prop-types';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Collapse } from 'react-collapse';
import { toast } from 'react-toastify';
// import ReactApexChart from 'react-apexcharts';

import apiHandler from '../api/axios-api';

import thermometer from '../images/thermometer.png';
import humidity from '../images/humidity.png';
import co2 from '../images/co2-cloud.png';
import intensity from '../images/intensity.png';

class AmbientController extends React.Component {
  constructor(props) {
    super(props);
    let setting_dict = {
      'is_co2': false,
      'co2_enable': false,
      'co2_high': '',
      'co2_low': '',
      'is_temperature': false,
      'temperature_enable': false,
      'temperature_high': '',
      'temperature_low': '',
      'is_humidity': false,
      'humidity_enable': false,
      'humidity_high': '',
      'humidity_low': '',
    };
    if ('settings' in this.props.deviceData) {
      let settings = this.props.deviceData.settings || {};
      if ('alarms' in settings) {
        settings.alarms.forEach((setting) => {
          if (setting.parameter === 'CO2') {
            setting_dict['co2_enable'] = setting.enable || false;
            setting_dict['co2_low'] = setting.low || '';
            setting_dict['co2_high'] = setting.high || '';
          }
          if (setting.parameter === 'TEMPERATURE') {
            setting_dict['temperature_enable'] = setting.enable || false;
            setting_dict['temperature_low'] = setting.low || '';
            setting_dict['temperature_high'] = setting.high || '';
          }
          if (setting.parameter === 'HUMIDITY') {
            setting_dict['humidity_enable'] = setting.enable || false;
            setting_dict['humidity_low'] = setting.low || '';
            setting_dict['humidity_high'] = setting.high || '';
          }
        });
      }
    }
    // check alarms available
    if (this.props.deviceData.data.alarms !== undefined) {
      setting_dict['is_co2'] = true;
      setting_dict['is_temperature'] = true;
      setting_dict['is_humidity'] = true;
    }

    this.state = Object.assign({}, {
      device_id: this.props.deviceId,
      device_data: this.getDeviceData(),
      chart_historical: [],
    }, setting_dict);

    // this.historicalOptions = {
    //   // colors: [
    //   //   '#FFAAAA',
    //   //   '#AAFFAA',
    //   //   '#AAAAFF',
    //   // ],
    //   chart: {
    //     toolbar: {
    //       show: false
    //     },
    //   },
    //   stroke: {
    //     width: 3
    //   },
    //   dataLabels: {
    //     enabled: false
    //   },
    //   xaxis: {
    //     type: 'datetime',
    //   },
    //   yaxis: [
    //     {
    //       title: {
    //         text: 'Temperature'
    //       },
    //       seriesName: 'Temperature'
    //     },
    //     {
    //       title: {
    //         text: 'Humidity'
    //       },
    //       seriesName: 'Humidity'
    //     },
    //     {
    //       title: {
    //         text: 'CO2'
    //       },
    //       opposite: true,
    //       seriesName: 'CO2'
    //     },
    //     {
    //       title: {
    //         text: 'Intensity'
    //       },
    //       opposite: true,
    //       seriesName: 'Intensity'
    //     }
    //   ],
    //   tooltip: {
    //     x: {
    //       format: 'dd/MMM/yyyy hh:mm:ss'
    //     }
    //   },
    // };

  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.deviceData) !== JSON.stringify(this.props.deviceData)) {
      this.setState({
        device_data: this.getDeviceData()
      });
    }
    // if (JSON.stringify(prevProps.deviceHistorical) !== JSON.stringify(this.props.deviceHistorical)) {
    //   // process historical data
    //   const chartHistoricalSeriesData = [];
    //   const temperatureSerie = [];
    //   const humiditySerie = [];
    //   const co2Serie = [];
    //   const intensitySerie = [];
    //   var data = this.props.deviceHistorical;
    //   data.time.map((t, i) => {
    //     const unixTime = Date.parse(t) + 25200000;
    //     temperatureSerie.push([unixTime, data.temperature[i]]);
    //     humiditySerie.push([unixTime, data.humidity[i]]);
    //     co2Serie.push([unixTime, data.co2[i]]);
    //     intensitySerie.push([unixTime, data.intensity[i]]);
    //     return null;
    //   });
    //   if (temperatureSerie.every(element => element[1] !== null)) {
    //     console.log('contains temperature');
    //     chartHistoricalSeriesData.push({ name: 'Temperature', data: temperatureSerie });
    //   }
    //   if (humiditySerie.every(element => element[1] !== null)) {
    //     console.log('contains humidity');
    //     chartHistoricalSeriesData.push({ name: 'Humidity', data: humiditySerie });
    //   }
    //   if (co2Serie.every(element => element[1] !== null)) {
    //     console.log('contains co2');
    //     chartHistoricalSeriesData.push({ name: 'CO2', data: co2Serie });
    //   }
    //   if (intensitySerie.every(element => element[1] !== null)) {
    //     console.log('contains intensity');
    //     chartHistoricalSeriesData.push({ name: 'Intensity', data: intensitySerie });
    //   }
    //   this.setState({
    //     chart_historical: chartHistoricalSeriesData,
    //   });
    // }
  }

  getDeviceData() {
    let _deviceData = this.props.deviceData;
    if (!_deviceData.data) {
      _deviceData.data = { status: [] };
    }
    return _deviceData;
  }

  // getDeviceHistorical() {
  //   let _deviceHistorical = this.props.deviceHistorical;
  //   if (!_deviceHistorical.data) {
  //     _deviceHistorical.data = { status: [] };
  //   }
  //   return _deviceHistorical;
  // }

  render() {
    const { classes } = this.props;

    // check for number of sensors
    let ambClass = "col-4";
    let ambientSensorCount = 0;
    if (this.state.device_data.data?.status?.temperature !== null) ambientSensorCount++;
    if (this.state.device_data.data?.status?.humidity !== null) ambientSensorCount++;
    if (this.state.device_data.data?.status?.co2 !== null) ambientSensorCount++;
    if (this.state.device_data.data?.status?.intensity !== null) ambientSensorCount++;

    switch (ambientSensorCount) {
      case 4:
        ambClass = "col-3";
        break;
      default:
        ambClass = "col-4";
    }

    // alarm card color
    let is_alarm = false;
    if (this.state.device_data.data) {
      if ((this.state.device_data.data.alarms ?? []).length > 0) {
        is_alarm = true;
      }
    }
    let card_color_class;
    if (this.state.device_data.data && this.state.device_data.online) {
      card_color_class = is_alarm ? classes.online_bgAlert : classes.online_bg;
    }
    else {
      card_color_class = is_alarm ? classes.offline_bgAlert : classes.offline_bg;
    }


    return (
      <React.Fragment>
        <div>
          <div className={`card mb-2 ${card_color_class}`}>
            <div className="card-body">

              <div className="row">
                {this.state.device_data.productType === 'TH_AMBIENT' && (
                  <React.Fragment>
                    {this.state.device_data.data.status.temperature !== null && (
                      <div className={"text-center " + ambClass + " mt-1"}>
                        <div className="card-text">
                          <img src={thermometer} width="50%" alt="Temperature icon" />
                          <p className="pt-3">
                            {[undefined, null].includes(this.state.device_data.data.status.temperature) ? '-' : this.state.device_data.data.status.temperature.toFixed(1)} °C
                          </p>
                        </div>
                      </div>
                    )}

                    {this.state.device_data.data.status.humidity !== null && (
                      <div className={"text-center " + ambClass + " mt-1"}>
                        <div className="card-text">
                          <img src={humidity} width="50%" alt="Humidity icon" />
                          <p className="pt-3">
                            {[undefined, null].includes(this.state.device_data.data.status.humidity) ? '-' : this.state.device_data.data.status.humidity.toFixed(1)} %RH
                          </p>
                        </div>
                      </div>
                    )}

                    {this.state.device_data.data.status.co2 !== null && (
                      <div className={"text-center " + ambClass + " mt-1"}>
                        <div className="card-text">
                          <img src={co2} width="50%" alt="CO2 icon" />
                          <p className="pt-3">
                            {[undefined, null].includes(this.state.device_data.data.status.co2) ? '-' : this.state.device_data.data.status.co2.toFixed(0)} ppm
                          </p>
                        </div>
                      </div>
                    )}

                    {this.state.device_data.data.status.intensity !== null && (
                      <div className={"text-center " + ambClass + " mt-1"}>
                        <div className="card-text">
                          <img src={intensity} width="50%" alt="Intensity icon" />
                          <p className="pt-3">
                            {[undefined, null].includes(this.state.device_data.data.status.intensity) ? '-' : this.state.device_data.data.status.intensity.toFixed(0)} lux
                          </p>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>

            </div>
          </div>
          {/* <div>
            <ReactApexChart type="line" series={this.state.chart_historical} options={this.historicalOptions} height={400} />
          </div> */}
          {this.state.is_temperature && (<div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'moccasin' : 'lightgrey' }}>
            <div className="card-body">
              <div className="row">
                <h5 className="text-left col-12">Temperature Alarm</h5>
              </div>
              <div className="row">
                <div className="text-left col-6 mt-4">Enable</div>
                <div className="text-right col-6 mt-3">
                  <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state.temperature_enable} onChange={
                    (checked) => {
                      this.setState({
                        temperature_enable: checked
                      });
                      if (this.state.temperature_low !== '' && this.state.temperature_high !== '') {
                        apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                          alarms:
                            [
                              {
                                parameter: 'TEMPERATURE',
                                enable: checked,
                                low: this.state.temperature_low,
                                high: this.state.temperature_high,
                              }
                            ]
                        }).then((response) => {
                          toast.success("Success!");
                        }, (reason) => {
                          toast.error("Unable to perform your request");
                        });

                      }
                    }
                  } />
                </div>
              </div>
              <Collapse isOpened={this.state.temperature_enable}>
                <div className="row">
                  <div className="text-left col-8 mt-4">Low Level (°C)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="-40 to 120" value={this.state.temperature_low} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          temperature_low: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-8 mt-4">High Level (°C)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="-40 to 120" value={this.state.temperature_high} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          temperature_high: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-right col-12 mt-3">
                    <button type="button" className="btn btn-info" onClick={
                      (event) => {
                        if (this.state.temperature_low !== '' && this.state.temperature_high !== '') {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            alarms:
                              [
                                {
                                  parameter: 'TEMPERATURE',
                                  enable: this.state.temperature_enable,
                                  low: this.state.temperature_low,
                                  high: this.state.temperature_high,
                                }
                              ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                        }
                      }
                    } >Apply Settings</button>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>)}
          {this.state.is_humidity && (<div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'moccasin' : 'lightgrey' }}>
            <div className="card-body">
              <div className="row">
                <h5 className="text-left col-12">Humidity Alarm</h5>
              </div>
              <div className="row">
                <div className="text-left col-6 mt-4">Enable</div>
                <div className="text-right col-6 mt-3">
                  <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state.humidity_enable} onChange={
                    (checked) => {
                      this.setState({
                        humidity_enable: checked
                      });
                      if (this.state.humidity_low !== '' && this.state.humidity_high !== '') {
                        apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                          alarms:
                            [
                              {
                                parameter: 'HUMIDITY',
                                enable: checked,
                                low: this.state.humidity_low,
                                high: this.state.humidity_high,
                              }
                            ]
                        }).then((response) => {
                          toast.success("Success!");
                        }, (reason) => {
                          toast.error("Unable to perform your request");
                        });

                      }
                    }
                  } />
                </div>
              </div>
              <Collapse isOpened={this.state.humidity_enable}>
                <div className="row">
                  <div className="text-left col-8 mt-4">Low Level (%RH)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-100" value={this.state.humidity_low} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          humidity_low: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-8 mt-4">High Level (%RH)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-100" value={this.state.humidity_high} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          humidity_high: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-right col-12 mt-3">
                    <button type="button" className="btn btn-info" onClick={
                      (event) => {
                        if (this.state.humidity_low !== '' && this.state.humidity_high !== '') {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            alarms:
                              [
                                {
                                  parameter: 'HUMIDITY',
                                  enable: this.state.humidity_enable,
                                  low: this.state.humidity_low,
                                  high: this.state.humidity_high,
                                }
                              ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                        }
                      }
                    } >Apply Settings</button>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>)}
          {this.state.is_co2 && (<div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'moccasin' : 'lightgrey' }}>
            <div className="card-body">
              <div className="row">
                <h5 className="text-left col-12">CO2 Alarm</h5>
              </div>
              <div className="row">
                <div className="text-left col-6 mt-4">Enable</div>
                <div className="text-right col-6 mt-3">
                  <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state.co2_enable} onChange={
                    (checked) => {
                      this.setState({
                        co2_enable: checked
                      });
                      if (this.state.co2_low !== '' && this.state.co2_high !== '') {
                        apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                          alarms:
                            [
                              {
                                parameter: 'CO2',
                                enable: checked,
                                low: this.state.co2_low,
                                high: this.state.co2_high,
                              }
                            ]
                        }).then((response) => {
                          toast.success("Success!");
                        }, (reason) => {
                          toast.error("Unable to perform your request");
                        });

                      }
                    }
                  } />
                </div>
              </div>
              <Collapse isOpened={this.state.co2_enable}>
                <div className="row">
                  <div className="text-left col-8 mt-4">Low Level (ppm)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-10000" value={this.state.co2_low} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          co2_low: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-8 mt-4">High Level (ppm)</div>
                  <div className="text-right col-4 mt-3">
                    <input type="text" className="form-control" placeholder="0-10000" value={this.state.co2_high} maxLength={5} onChange={
                      (event) => {
                        this.setState({
                          co2_high: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-right col-12 mt-3">
                    <button type="button" className="btn btn-info" onClick={
                      (event) => {
                        if (this.state.co2_low !== '' && this.state.co2_high !== '') {
                          apiHandler.patch('devices/' + this.props.deviceId + '/settings', {
                            alarms:
                              [
                                {
                                  parameter: 'CO2',
                                  enable: this.state.co2_enable,
                                  low: this.state.co2_low,
                                  high: this.state.co2_high,
                                }
                              ]
                          }).then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                        }
                      }
                    } >Apply Settings</button>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>)}
        </div>
      </React.Fragment >
    )
  }
}

AmbientController.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceData: PropTypes.object.isRequired,
  deviceHistorical: PropTypes.object,
  classes: PropTypes.object,
};

export default AmbientController;
