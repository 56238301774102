import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";

import SummaryCard from '../components/summary-card';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import apiHandler from '../api/axios-api';
import MaintenanceCard from '../components/maintenance-card';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    let token = localStorage.getItem('access_token');
    let decoded;
    try {
      decoded = jwt_decode(token);
    }
    catch {
      decoded = {};
    }
    this.state = this.props.state || {
      devices: null,
      profile_picture: null,
      fetch_timer: null,
      pageYOffset: 0,
      display_name: decoded.display_name || "No Display Name",
    }
  }

  async fetchDevices() {
    apiHandler.get('devices')
      .then((response) => {
        if (response.status !== 200) {
          return null;
        }
        else {
          return response.data
        }
      }).then((body) => {
        // console.log(body.results);
        this.setState({
          devices: body.results
        });
      });
  }

  async fetchProfile() {
    let picture_data = localStorage.getItem('profile_picture');
    if (picture_data) {
      this.setState({
        profile_picture: picture_data
      });
    } else {
      apiHandler.get('profile/picture', { responseType: 'arraybuffer' })
        .then((response) => {
          if (response.status === 200) {
            const data = `data:${response.headers['content-type']};base64,${Buffer(response.data, 'binary').toString('base64')}`;
            localStorage.setItem('profile_picture', data);
            this.setState({
              profile_picture: data
            });
          }
          else {
            localStorage.removeItem('profile_picture');
          }
        }).catch((reason) => {
          // do nothing
        });
    }
  }

  async componentDidMount() {
    this.fetchDevices.bind(this)();
    this.fetchProfile.bind(this)();
    this.setState({
      fetch_timer: setInterval(this.fetchDevices.bind(this), 3000)
    });
    window.scrollTo(0, this.state.pageYOffset);
  }

  componentWillUnmount() {
    if (this.state.fetch_timer) {
      clearInterval(this.state.fetch_timer);
    }
    this.props.setHomeState({ ...this.state, pageYOffset: window.pageYOffset });
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed-top" style={{ backgroundColor: '#64BC83' }}>
          <MaintenanceCard />
          <div className="row my-2">
            <div className="text-left col-12">
              <div className="row mx-1">
                <div className="mx-3">
                  {this.state.profile_picture && (
                    <React.Fragment>
                      <div className="ml-2" />
                      {this.state.profile_picture && (
                        <div className="rounded-circle"
                          style={{
                            border: "2px solid #FFFFFF",
                            width: "70px",
                            height: "70px",
                            backgroundSize: "cover",
                            backgroundPositionX: "center",
                            backgroundPositionY: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundImage: `url(${this.state.profile_picture})`
                          }} />
                      )}
                    </React.Fragment>
                  )}
                </div>
                <div className="text-light mt-4">
                  <h5>Hi, {this.state.display_name}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-1">
            <div style={{ paddingLeft: 16 }} />
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <div className="nav-link active">All</div>
              </li>
            </ul>
          </div>
        </div>

        <div className="">
          {/* <div style={{ paddingTop: 268 }} /> */}
          <div style={{ paddingTop: 140 }} />

          <div className="row m-0 p-0">

            {this.state.devices === null ?
              (
                <div className="text-center mb-3 col-12">
                  <div className="alert alert-secondary" role="alert">
                    Loading..
                  </div>
                </div>
              )
              :
              this.state.devices.length > 0 ?
                this.state.devices.map((device, index) => {
                  return (
                    <div className="mb-2 col-md-6 mb-md-2 col-lg-4 mb-lg-3 col-xl-3 mb-lg-3">
                      <SummaryCard key={index} deviceId={device.deviceId} deviceData={device} />
                    </div>
                  )
                }
                )
                :
                (
                  <div className="text-center mb-3 col-12">
                    <div className="alert alert-secondary" role="alert">
                      No any device found
                    </div>
                  </div>
                )}

          </div>

        </div>

        <div style={{ paddingTop: 65 }} />

        <div className="fixed-bottom" style={{ backgroundColor: '#64BC83' }}>
          <div className="row">
            <div className="text-center col-4 mt-2">
              <Link to="/app/home" className="text-light">
                <h4><FontAwesomeIcon icon={faHome} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/notification" className="text-dark">
                <h4><FontAwesomeIcon icon={faBell} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/settings" className="text-dark">
                <h4><FontAwesomeIcon icon={faCog} /></h4>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

HomePage.propTypes = {
  state: PropTypes.object,
  setHomeState: PropTypes.func.isRequired,
};


export default HomePage;
