import React from 'react';

class CreditsPage extends React.Component {
  render() {
    return (
      <React.Fragment>

        <div className="container">
          <h2 className="my-2">Credits</h2>

          <p>
            Icons made by <a href="https://www.freepik.com" title="Freepik" >Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon" >www.flaticon.com</a>
          </p>

        </div>
      </React.Fragment>
    )
  }
}

export default CreditsPage;
