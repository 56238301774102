import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import LoginPage from '../pages/login';
import HomePage from '../pages/home';
import DevicePage from '../pages/device';
import DeviceSettingsPage from '../pages/device-settings';
import NotificationPage from '../pages/notification';
import SettingsPage from '../pages/settings';
import TosPage from '../pages/tos';
import AboutPage from '../pages/about';
import ModalPdpa from '../components/modal-pdpa';

import { createTheme, ThemeProvider } from '@mui/material';
import { ToastContainer, Slide } from 'react-toastify';

import apiHandler from '../api/axios-api'

import './App.css';
import ProfilePage from '../pages/profile';
import CreditsPage from '../pages/credits';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, deleteToken, onMessage } from "firebase/messaging";
import MaintenanceNoticePage from '../pages/maintenance-notice';


var firebaseConfig = {
  apiKey: "AIzaSyCLrpsBzT_hizLEnErVrQ6mmph75Y27qmE",
  authDomain: "agrio-app.firebaseapp.com",
  projectId: "agrio-app",
  storageBucket: "agrio-app.appspot.com",
  messagingSenderId: "770625161538",
  appId: "1:770625161538:web:e8189e8ada02c67fcca0db",
  measurementId: "G-4GXLLT1C8Z"
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

const requestForToken = () => {
  return getToken(messaging, { vapidKey: 'BIEnSEyaKmmRNtOnxNxlNLF00PL4T-UGVsd-gw_dpxT75SJKXri-CtPQfDFl6J7dCZ2hzCeKeLrhk0RGR-SBbbs' })
    .then((currentToken) => {
      if (currentToken) {
        // register token on the server
        apiHandler.post('notification/subscribe', {
          subscription: {
            token: currentToken,
            type: 'firebase',
          },
        });
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

const removeToken = () => {
  return deleteToken(messaging)
    .then((status) => {
      if (status) {
        console.log('Messaging token has been removed');
      }
      else {
        console.log('Unable to remove messaging token');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
}

// const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });

const theme = createTheme({
  typography: {
    fontFamily: [
      'Viga',
    ].join(','),
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      roles: '',
      homeState: null,
    }
    this.authenticationTest = this.authenticationTest.bind(this);
    this.setHomeState = this.setHomeState.bind(this);
  }

  componentDidMount() {
    this.authenticationTest();
    onMessage(messaging, (payload) => {
      console.log('[App.js] Received foreground message ', payload);
      // notification with browser service
      const notificationTitle = payload.data.title;
      const notificationOptions = {
        body: payload.data.body,
        icon: 'https://agrio.agrowlab.com/favicon.ico'
      };
      new Notification(notificationTitle, notificationOptions);
    });
  }

  authenticationTest() {
    let token = localStorage.getItem('access_token');
    if (token) {
      requestForToken();
    }
  }

  handleLogout() {
    try {
      console.log('logging out..');
      apiHandler.defaults.headers['Authorization'] = null;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('profile_picture');
      removeToken();
      return (
        <Redirect to='/app/login' />
      )
    }
    catch (e) {
      console.log(e);
    }
  };

  setHomeState(state) {
    this.setState({
      homeState: state,
    });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path='/app/logout'>
              <this.handleLogout />
            </Route>
            <Route exact path='/app/home'
              render={(props) => (
                <HomePage {...props} state={this.state.homeState} setHomeState={this.setHomeState} />
              )}
            />
            <Route exact path='/app/device/:device_id?' component={DevicePage} />
            <Route exact path='/app/device/settings/:device_id?' component={DeviceSettingsPage} />
            <Route exact path='/app/notification' component={NotificationPage} />
            <Route exact path='/app/settings' component={SettingsPage} />
            <Route exact path='/app/profile' component={ProfilePage} />
            <Route exact path='/app/login' component={LoginPage} />
            <Route exact path='/app/tos' component={TosPage} />
            <Route exact path='/app/about' component={AboutPage} />
            <Route exact path='/app/credits' component={CreditsPage} />
            <Route exact path='/app/maintenance-notice' component={MaintenanceNoticePage} />
            <Redirect to='/app/home/' />
          </Switch>
        </Router>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={true}
          closeButton={false}
          draggable={false}
          pauseOnHover={false}
          transition={Slide}
        />
        <ModalPdpa />
      </ThemeProvider>
    )
  }
}

export default App
