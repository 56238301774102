import React from "react";
import PropTypes from "prop-types";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Collapse } from "react-collapse";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import MobileTimePicker from "@mui/lab/MobileTimePicker";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiHandler from "../api/axios-api";

class AmbientControllerController extends React.Component {
  constructor(props) {
    super(props);
    let setting_dict = {
      is_temperature: false,
      temperature_power: false,
      temperature_enable: false,
      temperature_min: "",
      temperature_max: "",
      temperature_schedule_enable: false,
      temperature_schedule_start: new Date(),
      temperature_schedule_end: new Date(),

      is_humidity: false,
      humidity_power: false,
      humidity_enable: false,
      humidity_min: "",
      humidity_max: "",
      humidity_schedule_enable: false,
      humidity_schedule_start: new Date(),
      humidity_schedule_end: new Date(),

      is_co2: false,
      co2_power: false,
      co2_enable: false,
      co2_min: "",
      co2_max: "",
      co2_schedule_enable: false,
      co2_schedule_start: new Date(),
      co2_schedule_end: new Date(),

      is_intensity: false,
      intensity_power: false,
      intensity_enable: false,
      intensity_min: "",
      intensity_max: "",
      intensity_schedule_enable: false,
      intensity_schedule_start: new Date(),
      intensity_schedule_end: new Date(),
    };
    if ("settings" in this.props.deviceData) {
      let settings = this.props.deviceData.settings || {};
      if ("setpoints" in settings) {
        settings.setpoints.forEach((setting) => {
          if (setting.parameter === "TEMPERATURE") {
            setting_dict["temperature_power"] = setting.power || false;
            setting_dict["temperature_enable"] = setting.enable || false;
            setting_dict["temperature_min"] = setting.min || "";
            setting_dict["temperature_max"] = setting.max || "";
            if (setting.schedule.length === 2) {
              let api_start_time = parseInt(setting.schedule[0].time);
              if (isNaN(api_start_time)) {
                api_start_time = 80000;
              }
              let render_start_time = new Date();
              render_start_time.setHours((api_start_time / 10000) % 100);
              render_start_time.setMinutes((api_start_time / 100) % 100);
              render_start_time.setSeconds(0);
              setting_dict["temperature_schedule_start"] = render_start_time;
              let api_end_time = parseInt(setting.schedule[1].time);
              if (isNaN(api_end_time)) {
                api_end_time = 180000;
              }
              let render_end_time = new Date();
              render_end_time.setHours((api_end_time / 10000) % 100);
              render_end_time.setMinutes((api_end_time / 100) % 100);
              render_end_time.setSeconds(0);
              setting_dict["temperature_schedule_end"] = render_end_time;
              setting_dict["temperature_schedule_enable"] = true;
            }
          } else if (setting.parameter === "HUMIDITY") {
            setting_dict["humidity_power"] = setting.power || false;
            setting_dict["humidity_enable"] = setting.enable || false;
            setting_dict["humidity_min"] = setting.min || "";
            setting_dict["humidity_max"] = setting.max || "";
            if (setting.schedule.length === 2) {
              let api_start_time = parseInt(setting.schedule[0].time);
              if (isNaN(api_start_time)) {
                api_start_time = 80000;
              }
              let render_start_time = new Date();
              render_start_time.setHours((api_start_time / 10000) % 100);
              render_start_time.setMinutes((api_start_time / 100) % 100);
              render_start_time.setSeconds(0);
              setting_dict["humidity_schedule_start"] = render_start_time;
              let api_end_time = parseInt(setting.schedule[1].time);
              if (isNaN(api_end_time)) {
                api_end_time = 180000;
              }
              let render_end_time = new Date();
              render_end_time.setHours((api_end_time / 10000) % 100);
              render_end_time.setMinutes((api_end_time / 100) % 100);
              render_end_time.setSeconds(0);
              setting_dict["humidity_schedule_end"] = render_end_time;
              setting_dict["humidity_schedule_enable"] = true;
            }
          } else if (setting.parameter === "CO2") {
            setting_dict["co2_power"] = setting.power || false;
            setting_dict["co2_enable"] = setting.enable || false;
            setting_dict["co2_min"] = setting.min || "";
            setting_dict["co2_max"] = setting.max || "";
            if (setting.schedule.length === 2) {
              let api_start_time = parseInt(setting.schedule[0].time);
              if (isNaN(api_start_time)) {
                api_start_time = 80000;
              }
              let render_start_time = new Date();
              render_start_time.setHours((api_start_time / 10000) % 100);
              render_start_time.setMinutes((api_start_time / 100) % 100);
              render_start_time.setSeconds(0);
              setting_dict["co2_schedule_start"] = render_start_time;
              let api_end_time = parseInt(setting.schedule[1].time);
              if (isNaN(api_end_time)) {
                api_end_time = 180000;
              }
              let render_end_time = new Date();
              render_end_time.setHours((api_end_time / 10000) % 100);
              render_end_time.setMinutes((api_end_time / 100) % 100);
              render_end_time.setSeconds(0);
              setting_dict["co2_schedule_end"] = render_end_time;
              setting_dict["co2_schedule_enable"] = true;
            }
          } else if (setting.parameter === "INTENSITY") {
            setting_dict["intensity_power"] = setting.power || false;
            setting_dict["intensity_enable"] = setting.enable || false;
            setting_dict["intensity_min"] = setting.min || "";
            setting_dict["intensity_max"] = setting.max || "";
            if (setting.schedule.length === 2) {
              let api_start_time = parseInt(setting.schedule[0].time);
              if (isNaN(api_start_time)) {
                api_start_time = 80000;
              }
              let render_start_time = new Date();
              render_start_time.setHours((api_start_time / 10000) % 100);
              render_start_time.setMinutes((api_start_time / 100) % 100);
              render_start_time.setSeconds(0);
              setting_dict["intensity_schedule_start"] = render_start_time;
              let api_end_time = parseInt(setting.schedule[1].time);
              if (isNaN(api_end_time)) {
                api_end_time = 180000;
              }
              let render_end_time = new Date();
              render_end_time.setHours((api_end_time / 10000) % 100);
              render_end_time.setMinutes((api_end_time / 100) % 100);
              render_end_time.setSeconds(0);
              setting_dict["intensity_schedule_end"] = render_end_time;
              setting_dict["intensity_schedule_enable"] = true;
            }
          }
        });
      }
    }
    if (this.props.deviceData.productVariant === "") {
      console.log("all features enabled");
      setting_dict["is_temperature"] = true;
      setting_dict["is_humidity"] = true;
      setting_dict["is_co2"] = true;
      setting_dict["is_intensity"] = true;
    } else {
      let features = (this.props.deviceData.productVariant || "").split(",");
      if (features.includes("TEMP")) {
        console.log("temperature eligibled");
        setting_dict["is_temperature"] = true;
      }
      if (features.includes("HUMID")) {
        console.log("humidity eligibled");
        setting_dict["is_humidity"] = true;
      }
      if (features.includes("CO2")) {
        console.log("co2 eligibled");
        setting_dict["is_co2"] = true;
      }
      if (features.includes("INT")) {
        console.log("intensity eligibled");
        setting_dict["is_intensity"] = true;
      }
    }
    this.state = Object.assign(
      {},
      {
        device_id: this.props.deviceId,
        device_data: this.props.deviceData,
      },
      setting_dict
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deviceData !== this.props.deviceData) {
      this.setState({
        device_data: this.props.deviceData,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div>
          {this.state.is_temperature && (
            <div
              className="card mb-2"
              style={{
                backgroundColor: this.state.device_data.online
                  ? "#e1f1e6"
                  : "lightgrey",
              }}
            >
              <div className="card-body">
                <div className="row">
                  <h5 className="text-left col-12">Temperature</h5>
                </div>
                <div className="row">
                  <div className="text-left col-6 mt-4">Power</div>
                  <div className="text-right col-6 mt-3">
                    <BootstrapSwitchButton
                      onstyle="success"
                      offstyle="danger"
                      width={60}
                      checked={this.state.temperature_power}
                      onChange={(checked) => {
                        this.setState({
                          temperature_power: checked,
                        });
                        apiHandler
                          .patch(
                            "devices/" + this.props.deviceId + "/settings",
                            {
                              setpoints: [
                                {
                                  parameter: "TEMPERATURE",
                                  power: checked,
                                },
                              ],
                            }
                          )
                          .then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="text-left col-6 mt-4">Enable Control</div>
                  <div className="text-right col-6 mt-3">
                    <BootstrapSwitchButton
                      onstyle="success"
                      offstyle="danger"
                      width={60}
                      checked={this.state.temperature_enable}
                      onChange={(checked) => {
                        this.setState({
                          temperature_power: checked ? checked : this.state.temperature_power,
                          temperature_enable: checked,
                        });
                        if (checked === false) {
                          apiHandler
                            .patch(
                              "devices/" + this.props.deviceId + "/settings",
                              {
                                setpoints: [
                                  {
                                    parameter: "TEMPERATURE",
                                    power: checked ? checked : this.state.temperature_power,
                                    enable: checked,
                                  },
                                ],
                              }
                            )
                            .then((response) => {
                              toast.success("Success!");
                            }, (reason) => {
                              toast.error("Unable to perform your request");
                            });
                        }
                      }}
                    />
                  </div>
                </div>
                <Collapse isOpened={this.state.temperature_enable}>
                  <div className="row">
                    <div className="text-left col-8 mt-4">
                      Temperature Min (°C)
                    </div>
                    <div className="text-right col-4 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="18-30"
                        value={this.state.temperature_min}
                        maxLength={5}
                        onChange={(event) => {
                          this.setState({
                            temperature_min: event.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-8 mt-4">
                      Temperature Max (°C)
                    </div>
                    <div className="text-right col-4 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="18-30"
                        value={this.state.temperature_max}
                        maxLength={5}
                        onChange={(event) => {
                          this.setState({
                            temperature_max: event.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-6 mt-4">Control Schedule</div>
                    <div className="text-right col-6 mt-3">
                      <BootstrapSwitchButton
                        onstyle="success"
                        offstyle="danger"
                        width={60}
                        checked={this.state.temperature_schedule_enable}
                        onChange={(checked) => {
                          this.setState({
                            temperature_schedule_enable: checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <Collapse isOpened={this.state.temperature_schedule_enable}>
                    <div className="row">
                      <div className="text-left col-8 mt-4">Start Time</div>
                      <div className="text-right col-4 mt-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileTimePicker
                            fullWidth={true}
                            label={""}
                            value={this.state.temperature_schedule_start}
                            onChange={(newValue) => {
                              this.setState({
                                temperature_schedule_start: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="row">
                      <div className="text-left col-8 mt-4">End Time</div>
                      <div className="text-right col-4 mt-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileTimePicker
                            fullWidth={true}
                            label={""}
                            value={this.state.temperature_schedule_end}
                            onChange={(newValue) => {
                              this.setState({
                                temperature_schedule_end: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Collapse>
                  <div className="row">
                    <div className="text-right col-12 mt-3">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={(event) => {
                          if (
                            this.state.temperature_min !== "" &&
                            this.state.temperature_max !== ""
                          ) {
                            // set schedules
                            let scheduleMessage = [];
                            if (this.state.temperature_schedule_enable) {
                              let start_time = (this.state.temperature_schedule_start.getHours() * 10000) + (this.state.temperature_schedule_start.getMinutes() * 100);
                              let end_time = (this.state.temperature_schedule_end.getHours() * 10000) + (this.state.temperature_schedule_end.getMinutes() * 100);
                              scheduleMessage = [
                                {
                                  time: start_time,
                                  power: true,
                                  enable: true,
                                },
                                {
                                  time: end_time,
                                  power: false,
                                  enable: true,
                                }
                              ];
                            }
                            // send message
                            apiHandler
                              .patch(
                                "devices/" + this.props.deviceId + "/settings",
                                {
                                  setpoints: [
                                    {
                                      parameter: "TEMPERATURE",
                                      enable: this.state.temperature_enable,
                                      min: this.state.temperature_min,
                                      max: this.state.temperature_max,
                                      schedule: scheduleMessage,
                                    },
                                  ],
                                }
                              )
                              .then((response) => {
                                toast.success("Success!");
                              }, (reason) => {
                                toast.error("Unable to perform your request");
                              });

                          }
                        }}
                      >
                        Apply Settings
                      </button>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          )}
          {this.state.is_humidity && (
            <div
              className="card mb-2"
              style={{
                backgroundColor: this.state.device_data.online
                  ? "#e1f1e6"
                  : "lightgrey",
              }}
            >
              <div className="card-body">
                <div className="row">
                  <h5 className="text-left col-12">Humidity</h5>
                </div>
                <div className="row">
                  <div className="text-left col-6 mt-4">Power</div>
                  <div className="text-right col-6 mt-3">
                    <BootstrapSwitchButton
                      onstyle="success"
                      offstyle="danger"
                      width={60}
                      checked={this.state.humidity_power}
                      onChange={(checked) => {
                        this.setState({
                          humidity_power: checked,
                        });
                        apiHandler
                          .patch(
                            "devices/" + this.props.deviceId + "/settings",
                            {
                              setpoints: [
                                {
                                  parameter: "HUMIDITY",
                                  power: checked,
                                },
                              ],
                            }
                          )
                          .then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-6 mt-4">Enable Control</div>
                  <div className="text-right col-6 mt-3">
                    <BootstrapSwitchButton
                      onstyle="success"
                      offstyle="danger"
                      width={60}
                      checked={this.state.humidity_enable}
                      onChange={(checked) => {
                        this.setState({
                          humidity_power: checked ? checked : this.state.humidity_power,
                          humidity_enable: checked,
                        });
                        if (checked === false) {
                          apiHandler
                            .patch(
                              "devices/" + this.props.deviceId + "/settings",
                              {
                                setpoints: [
                                  {
                                    parameter: "HUMIDITY",
                                    power: checked ? checked : this.state.humidity_power,
                                    enable: checked,
                                  },
                                ],
                              }
                            )
                            .then((response) => {
                              toast.success("Success!");
                            }, (reason) => {
                              toast.error("Unable to perform your request");
                            });
                        }
                      }}
                    />
                  </div>
                </div>
                <Collapse isOpened={this.state.humidity_enable}>
                  <div className="row">
                    <div className="text-left col-8 mt-4">
                      Humidity Min (%RH)
                    </div>
                    <div className="text-right col-4 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0-100"
                        value={this.state.humidity_min}
                        maxLength={5}
                        onChange={(event) => {
                          this.setState({
                            humidity_min: event.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-8 mt-4">
                      Humidity Max (%RH)
                    </div>
                    <div className="text-right col-4 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0-100"
                        value={this.state.humidity_max}
                        maxLength={5}
                        onChange={(event) => {
                          this.setState({
                            humidity_max: event.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-6 mt-4">Control Schedule</div>
                    <div className="text-right col-6 mt-3">
                      <BootstrapSwitchButton
                        onstyle="success"
                        offstyle="danger"
                        width={60}
                        checked={this.state.humidity_schedule_enable}
                        onChange={(checked) => {
                          this.setState({
                            humidity_schedule_enable: checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <Collapse isOpened={this.state.humidity_schedule_enable}>
                    <div className="row">
                      <div className="text-left col-8 mt-4">Start Time</div>
                      <div className="text-right col-4 mt-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileTimePicker
                            fullWidth={true}
                            label={""}
                            value={this.state.humidity_schedule_start}
                            onChange={(newValue) => {
                              this.setState({
                                humidity_schedule_start: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="row">
                      <div className="text-left col-8 mt-4">End Time</div>
                      <div className="text-right col-4 mt-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileTimePicker
                            fullWidth={true}
                            label={""}
                            value={this.state.humidity_schedule_end}
                            onChange={(newValue) => {
                              this.setState({
                                humidity_schedule_end: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Collapse>
                  <div className="row">
                    <div className="text-right col-12 mt-3">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={(event) => {
                          if (
                            this.state.humidity_min !== "" &&
                            this.state.humidity_max !== ""
                          ) {
                            // set schedules
                            let scheduleMessage = [];
                            if (this.state.humidity_schedule_enable) {
                              let start_time = (this.state.humidity_schedule_start.getHours() * 10000) + (this.state.humidity_schedule_start.getMinutes() * 100);
                              let end_time = (this.state.humidity_schedule_end.getHours() * 10000) + (this.state.humidity_schedule_end.getMinutes() * 100);
                              scheduleMessage = [
                                {
                                  time: start_time,
                                  power: true,
                                  enable: true,
                                },
                                {
                                  time: end_time,
                                  power: false,
                                  enable: true,
                                }
                              ];
                            }
                            // send message
                            apiHandler
                              .patch(
                                "devices/" + this.props.deviceId + "/settings",
                                {
                                  setpoints: [
                                    {
                                      parameter: "HUMIDITY",
                                      enable: this.state.humidity_enable,
                                      min: this.state.humidity_min,
                                      max: this.state.humidity_max,
                                      schedule: scheduleMessage,
                                    },
                                  ],
                                }
                              )
                              .then((response) => {
                                toast.success("Success!");
                              }, (reason) => {
                                toast.error("Unable to perform your request");
                              });

                          }
                        }}
                      >
                        Apply Settings
                      </button>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          )}
          {this.state.is_co2 && (
            <div
              className="card mb-2"
              style={{
                backgroundColor: this.state.device_data.online
                  ? "#e1f1e6"
                  : "lightgrey",
              }}
            >
              <div className="card-body">
                <div className="row">
                  <h5 className="text-left col-12">CO2</h5>
                </div>
                <div className="row">
                  <div className="text-left col-6 mt-4">Power</div>
                  <div className="text-right col-6 mt-3">
                    <BootstrapSwitchButton
                      onstyle="success"
                      offstyle="danger"
                      width={60}
                      checked={this.state.co2_power}
                      onChange={(checked) => {
                        this.setState({
                          co2_power: checked,
                        });
                        apiHandler
                          .patch(
                            "devices/" + this.props.deviceId + "/settings",
                            {
                              setpoints: [
                                {
                                  parameter: "CO2",
                                  power: checked,
                                },
                              ],
                            }
                          )
                          .then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-6 mt-4">Enable Control</div>
                  <div className="text-right col-6 mt-3">
                    <BootstrapSwitchButton
                      onstyle="success"
                      offstyle="danger"
                      width={60}
                      checked={this.state.co2_enable}
                      onChange={(checked) => {
                        this.setState({
                          co2_power: checked ? checked : this.state.co2_power,
                          co2_enable: checked,
                        });
                        if (checked === false) {
                          apiHandler
                            .patch(
                              "devices/" + this.props.deviceId + "/settings",
                              {
                                setpoints: [
                                  {
                                    parameter: "CO2",
                                    power: checked ? checked : this.state.co2_power,
                                    enable: checked,
                                  },
                                ],
                              }
                            )
                            .then((response) => {
                              toast.success("Success!");
                            }, (reason) => {
                              toast.error("Unable to perform your request");
                            });
                        }
                      }}
                    />
                  </div>
                </div>
                <Collapse isOpened={this.state.co2_enable}>
                  <div className="row">
                    <div className="text-left col-8 mt-4">CO2 Min (ppm)</div>
                    <div className="text-right col-4 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0-5000"
                        value={this.state.co2_min}
                        maxLength={5}
                        onChange={(event) => {
                          this.setState({
                            co2_min: event.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-8 mt-4">CO2 Max (ppm)</div>
                    <div className="text-right col-4 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0-5000"
                        value={this.state.co2_max}
                        maxLength={5}
                        onChange={(event) => {
                          this.setState({
                            co2_max: event.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-6 mt-4">Control Schedule</div>
                    <div className="text-right col-6 mt-3">
                      <BootstrapSwitchButton
                        onstyle="success"
                        offstyle="danger"
                        width={60}
                        checked={this.state.co2_schedule_enable}
                        onChange={(checked) => {
                          this.setState({
                            co2_schedule_enable: checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <Collapse isOpened={this.state.co2_schedule_enable}>
                    <div className="row">
                      <div className="text-left col-8 mt-4">Start Time</div>
                      <div className="text-right col-4 mt-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileTimePicker
                            fullWidth={true}
                            label={""}
                            value={this.state.co2_schedule_start}
                            onChange={(newValue) => {
                              this.setState({
                                co2_schedule_start: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="row">
                      <div className="text-left col-8 mt-4">End Time</div>
                      <div className="text-right col-4 mt-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileTimePicker
                            fullWidth={true}
                            label={""}
                            value={this.state.co2_schedule_end}
                            onChange={(newValue) => {
                              this.setState({
                                co2_schedule_end: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Collapse>
                  <div className="row">
                    <div className="text-right col-12 mt-3">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={(event) => {
                          if (
                            this.state.co2_min !== "" &&
                            this.state.co2_max !== ""
                          ) {
                            // set schedules
                            let scheduleMessage = [];
                            if (this.state.co2_schedule_enable) {
                              let start_time = (this.state.co2_schedule_start.getHours() * 10000) + (this.state.co2_schedule_start.getMinutes() * 100);
                              let end_time = (this.state.co2_schedule_end.getHours() * 10000) + (this.state.co2_schedule_end.getMinutes() * 100);
                              scheduleMessage = [
                                {
                                  time: start_time,
                                  power: true,
                                  enable: true,
                                },
                                {
                                  time: end_time,
                                  power: false,
                                  enable: true,
                                }
                              ];
                            }
                            // send message
                            apiHandler
                              .patch(
                                "devices/" + this.props.deviceId + "/settings",
                                {
                                  setpoints: [
                                    {
                                      parameter: "CO2",
                                      enable: this.state.co2_enable,
                                      min: this.state.co2_min,
                                      max: this.state.co2_max,
                                      schedule: scheduleMessage,
                                    },
                                  ],
                                }
                              )
                              .then((response) => {
                                toast.success("Success!");
                              }, (reason) => {
                                toast.error("Unable to perform your request");
                              });

                          }
                        }}
                      >
                        Apply Settings
                      </button>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          )}
          {this.state.is_intensity && (
            <div
              className="card mb-2"
              style={{
                backgroundColor: this.state.device_data.online
                  ? "#e1f1e6"
                  : "lightgrey",
              }}
            >
              <div className="card-body">
                <div className="row">
                  <h5 className="text-left col-12">Light Intensity</h5>
                </div>
                <div className="row">
                  <div className="text-left col-6 mt-4">Power</div>
                  <div className="text-right col-6 mt-3">
                    <BootstrapSwitchButton
                      onstyle="success"
                      offstyle="danger"
                      width={60}
                      checked={this.state.intensity_power}
                      onChange={(checked) => {
                        this.setState({
                          intensity_power: checked,
                        });
                        apiHandler
                          .patch(
                            "devices/" + this.props.deviceId + "/settings",
                            {
                              setpoints: [
                                {
                                  parameter: "INTENSITY",
                                  power: checked,
                                },
                              ],
                            }
                          )
                          .then((response) => {
                            toast.success("Success!");
                          }, (reason) => {
                            toast.error("Unable to perform your request");
                          });

                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-6 mt-4">Enable Control</div>
                  <div className="text-right col-6 mt-3">
                    <BootstrapSwitchButton
                      onstyle="success"
                      offstyle="danger"
                      width={60}
                      checked={this.state.intensity_enable}
                      onChange={(checked) => {
                        this.setState({
                          intensity_power: checked ? checked : this.state.intensity_power,
                          intensity_enable: checked,
                        });
                        if (checked === false) {
                          apiHandler
                            .patch(
                              "devices/" + this.props.deviceId + "/settings",
                              {
                                setpoints: [
                                  {
                                    parameter: "INTENSITY",
                                    power: checked ? checked : this.state.intensity_power,
                                    enable: checked,
                                  },
                                ],
                              }
                            )
                            .then((response) => {
                              toast.success("Success!");
                            }, (reason) => {
                              toast.error("Unable to perform your request");
                            });
                        }
                      }}
                    />
                  </div>
                </div>
                <Collapse isOpened={this.state.intensity_enable}>
                  <div className="row">
                    <div className="text-left col-8 mt-4">
                      Intensity Min (lux)
                    </div>
                    <div className="text-right col-4 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0-65535"
                        value={this.state.intensity_min}
                        maxLength={5}
                        onChange={(event) => {
                          this.setState({
                            intensity_min: event.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-8 mt-4">
                      Intensity Max (lux)
                    </div>
                    <div className="text-right col-4 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0-65535"
                        value={this.state.intensity_max}
                        maxLength={5}
                        onChange={(event) => {
                          this.setState({
                            intensity_max: event.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-left col-6 mt-4">Control Schedule</div>
                    <div className="text-right col-6 mt-3">
                      <BootstrapSwitchButton
                        onstyle="success"
                        offstyle="danger"
                        width={60}
                        checked={this.state.intensity_schedule_enable}
                        onChange={(checked) => {
                          this.setState({
                            intensity_schedule_enable: checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <Collapse isOpened={this.state.intensity_schedule_enable}>
                    <div className="row">
                      <div className="text-left col-8 mt-4">Start Time</div>
                      <div className="text-right col-4 mt-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileTimePicker
                            fullWidth={true}
                            label={""}
                            value={this.state.intensity_schedule_start}
                            onChange={(newValue) => {
                              this.setState({
                                intensity_schedule_start: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="row">
                      <div className="text-left col-8 mt-4">End Time</div>
                      <div className="text-right col-4 mt-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileTimePicker
                            fullWidth={true}
                            label={""}
                            value={this.state.intensity_schedule_end}
                            onChange={(newValue) => {
                              this.setState({
                                intensity_schedule_end: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Collapse>
                  <div className="row">
                    <div className="text-right col-12 mt-3">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={(event) => {
                          if (
                            this.state.intensity_min !== "" &&
                            this.state.intensity_max !== ""
                          ) {
                            // set schedules
                            let scheduleMessage = [];
                            if (this.state.intensity_schedule_enable) {
                              let start_time = (this.state.intensity_schedule_start.getHours() * 10000) + (this.state.intensity_schedule_start.getMinutes() * 100);
                              let end_time = (this.state.intensity_schedule_end.getHours() * 10000) + (this.state.intensity_schedule_end.getMinutes() * 100);
                              scheduleMessage = [
                                {
                                  time: start_time,
                                  power: true,
                                  enable: true,
                                },
                                {
                                  time: end_time,
                                  power: false,
                                  enable: true,
                                }
                              ];
                            }
                            // send message
                            apiHandler
                              .patch(
                                "devices/" + this.props.deviceId + "/settings",
                                {
                                  setpoints: [
                                    {
                                      parameter: "INTENSITY",
                                      enable: this.state.intensity_enable,
                                      min: this.state.intensity_min,
                                      max: this.state.intensity_max,
                                      schedule: scheduleMessage,
                                    },
                                  ],
                                }
                              )
                              .then((response) => {
                                toast.success("Success!");
                              }, (reason) => {
                                toast.error("Unable to perform your request");
                              });

                          }
                        }}
                      >
                        Apply Settings
                      </button>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

AmbientControllerController.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceData: PropTypes.object.isRequired,
};

export default AmbientControllerController;
