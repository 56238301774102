import React from 'react';

class TosPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <h2 className="my-2">Terms of Service</h2>

          <p>
            Please read these Terms of Service (“Terms”) carefully before using Agrio Application (“Service”, “Agrio”, “Application”) developed by AgrowLab Co., Ltd. (“AgrowLab”)
          </p>

          <p>
            Your access to and use of the Services is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all users and other who access or use the Service.
          </p>

          <p>
            By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access the Service.
          </p>

          <p>
            AgrowLab reserves the right to change this Agreement, and add additional Terms at any time,
            effective upon making the modified provisions available on the Application or by notifying user or other directly.
            You are responsible for regularly reviewing the Terms of Use for modifications and updates to its terms.
            Continued use of the Application after any such changes are made to this Agreement shall constitute your consent to such changes.
            Other than making the changes available on the Application,
            AgrowLab does not assume any obligation to notify users of any changes to this Agreement, or the creation or modification of any additional terms.
          </p>

          <h2 className="my-2">Warranty Disclaimer</h2>

          <p>
            Agrio is provided “as is” as available and without any warranties or conditions (express or implied,
            including the implied warranties of merchantability, accuracy, fitness for a particular purpose, title,
            and non-infringement, arising by statute or otherwise in law or from a course of dealing or usage or trade).
            AgrowLab disclaim all and make no representations or warranties, of any kind, either express or implied,
            as to the quality, identity, or reliability of any third party, or as to the accuracy of the postings made on Agrio. 
          </p>

          <h2 className="my-2">Modifications to Agrio</h2>

          <p>
            AgrowLab reserves the right at any time to modify or discontinue, temporarily or permanently,
            Agrio or the Service (or any part thereof) with or without notice.
            You agree that Agrio shall not be liable to you or to any third party for any modification,
            suspension or discontinuance of the Application or the Services.
            AgrowLab did not allowed user to modify Agrio in all cases.
          </p>

          <h2 className="my-2">Termination</h2>

          <p>
            AgrowLab may terminate or suspend user to our Service immediately, without prior notice or liability,
            for any reason whatsoever, including without limitation if you breach the Terms.
            All provision of the Terms which by their nature should survive termination shall survive termination,
            including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
          </p>

          <p className="mt-4 text-muted">
            Updated August, 2021
          </p>

        </div>
      </React.Fragment>
    )
  }
}

export default TosPage;
