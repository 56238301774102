import React from 'react';
import PropTypes from 'prop-types';

class NutrientSensorController extends React.Component {
  constructor(props) {
    super(props);
    let setting_dict = {
      'ec_enable': false,
      'ec_min': '',
      'ec_max': '',
      'ph_enable': false,
      'ph_min': '',
      'ph_max': '',
      'a_ml': '',
      'b_ml': '',
      'ph_ml': '',
      'dosing_interval': 0,
    };
    if ('settings' in this.props.deviceData) {
      let settings = this.props.deviceData.settings || {};
      if ('setpoints' in settings) {
        settings.setpoints.forEach((setting) => {
          if (setting.parameter === 'EC') {
            setting_dict['ec_enable'] = setting.enable || false;
            setting_dict['ec_min'] = setting.min || '';
            setting_dict['ec_max'] = setting.max || '';
          }
          else if (setting.parameter === 'PH') {
            setting_dict['ph_enable'] = setting.enable || false;
            setting_dict['ph_min'] = setting.min || '';
            setting_dict['ph_max'] = setting.max || '';
          }
        });
      }
      if ('advanced' in settings) {
        setting_dict['dosing_interval'] = settings.advanced.dosingInterval || 0;
      }
    }
    this.state = Object.assign({}, {
      device_id: this.props.deviceId,
      device_data: this.props.deviceData,
    }, setting_dict);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deviceData !== this.props.deviceData) {
      this.setState({
        device_data: this.props.deviceData
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'yellowgreen' : 'lightgrey' }}>
            <div className="card-body">
              <div className="row mt-2">
                <div className="text-left col-6">
                  <p className="mb-0"><b>EC</b></p>
                </div>
                <div className="text-left col-6">
                  <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.ec) ? '-' : this.state.device_data.data.status.ec.toFixed(0)} μS/cm</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="text-left col-6">
                  <p className="mb-0"><b>pH</b></p>
                </div>
                <div className="text-left col-6">
                  <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.ph) ? '-' : this.state.device_data.data.status.ph.toFixed(2)}</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="text-left col-6">
                  <p className="mb-0"><b>Temperature</b></p>
                </div>
                <div className="text-left col-6">
                  <p className="card-text">{[undefined, null].includes(this.state.device_data.data.status.temperature) ? '-' : this.state.device_data.data.status.temperature.toFixed(1)} °C</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    )
  }
}

NutrientSensorController.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceData: PropTypes.object.isRequired,
};

export default NutrientSensorController;
