import React from 'react';
import { Link } from "react-router-dom";
import { withStyles } from '@mui/styles';

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import apiHandler from '../api/axios-api';
import DimmerController from '../components/dimmer-controller';
import SwitchController from '../components/switch-controller';
import DoserController from '../components/doser-controller';
import AmbientController from '../components/ambient-controller';
import AmbientControllerController from '../components/ambient-controller-controller';
import NutrientSensorController from '../components/nutrient-sensor-controller';
import AlarmController from '../components/alarm-controller';
import MaintenanceCard from '../components/maintenance-card';

const useStyles = () => ({
  online_bg: {
    backgroundColor: `#e1f1e6`
  },
  online_bgAlert: {
    animation: `$blinkingBg 1s infinite`
  },
  offline_bg: {
    backgroundColor: `lightgrey`
  },
  offline_bgAlert: {
    backgroundColor: `#ffaaaa`
  },
  "@keyframes blinkingBg": {
    "0%": {
      color: '#fff',
      backgroundColor: '#FF4842',
    },
    "49%": {
      color: '#7A0C2E',
      backgroundColor: '#FFE7D9',
    },
    "50%": {
      color: '#7A0C2E',
      backgroundColor: '#FFE7D9',
    },
    // "99%": {
    //   color: '#7A0C2E',
    //   backgroundColor: '#FFE7D9',
    // },
    "100%": {
      color: '#fff',
      backgroundColor: '#FF4842',
    },
  },
});

class DevicePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device_id: this.props.match.params.device_id,
      device_data: null,
      device_historical: null,
    }
  }

  async fetchDeviceData() {
    apiHandler.get('devices/' + this.state.device_id)
      .then((response) => {
        if (response.status !== 200) {
          return null;
        }
        else {
          return response.data
        }
      }).then((body) => {
        this.setState({
          device_data: body,
        });
      });
  }

  async fetchHistorical(period = 1) {
    apiHandler.get(`devices/${this.state.device_id}/historical?p=${period}`)
      .then((response) => {
        if (response.status !== 200) {
          return null;
        }
        else {
          return response.data
        }
      }).then((body) => {
        this.setState({
          device_historical: body,
        });
      });
  }

  async componentDidMount() {
    this.fetchDeviceData.bind(this)();
    // this.fetchHistorical.bind(this)();
    this.setState({
      fetch_timer: setInterval(this.fetchDeviceData.bind(this), 3000)
    });
  }

  componentWillUnmount() {
    if (this.state.fetch_timer) {
      clearInterval(this.state.fetch_timer);
    }
  }

  render() {

    return (
      <React.Fragment>
        <div className={"fixed-top"} style={{ backgroundColor: '#64BC83' }}>
          <MaintenanceCard />
          <div className="row my-2 mx-1">
            <div className="text-left col-9 text-light mt-2">
              <h5>{this.state.device_data ? this.state.device_data.deviceName : this.state.device_id}</h5>
              <h6 className="card-subtitle mb-2 text-warning">Settings</h6>
            </div>
            <div className="text-right col-3 mt-2">
              <Link to="/app/home" className="text-light">Back</Link>

              <Link to={"/app/device/settings/" + this.state.device_id} className="text-light">
                <h4><FontAwesomeIcon icon={faCog} /></h4>
              </Link>

            </div>
          </div>
        </div>

        <div className="container">
          {/* <div style={{ paddingTop: 235 }} /> */}
          <div style={{ paddingTop: 107 }} />

          {this.state.device_data === null ?
            (
              <div className="text-center mb-3">
                <div className="alert alert-secondary" role="alert">
                  Loading..
                </div>
              </div>
            )
            :
            (
              <React.Fragment>
                {!this.state.device_data.online && (
                  <div className="text-center alert alert-danger" role="alert">
                    Device is offline
                  </div>
                )}
                {this.state.device_data.productType === 'TH_DIMMER' && (
                  <DimmerController deviceId={this.state.device_id} deviceData={this.state.device_data} deviceHistorical={this.state.device_historical} classes={this.props.classes} />
                )}
                {this.state.device_data.productType === 'TH_SWITCH' && (
                  <SwitchController deviceId={this.state.device_id} deviceData={this.state.device_data} deviceHistorical={this.state.device_historical} classes={this.props.classes} />
                )}
                {this.state.device_data.productType === 'TH_DOSER' && (
                  <DoserController deviceId={this.state.device_id} deviceData={this.state.device_data} deviceHistorical={this.state.device_historical} classes={this.props.classes} />
                )}
                {this.state.device_data.productType === 'TH_AMBIENT' && (
                  <AmbientController deviceId={this.state.device_id} deviceData={this.state.device_data} deviceHistorical={this.state.device_historical} classes={this.props.classes} />
                )}
                {this.state.device_data.productType === 'TH_AMBIENT_CTRL' && (
                  <AmbientControllerController deviceId={this.state.device_id} deviceData={this.state.device_data} deviceHistorical={this.state.device_historical} classes={this.props.classes} />
                )}
                {this.state.device_data.productType === 'TH_NUTRIENT_SENSOR' && (
                  <NutrientSensorController deviceId={this.state.device_id} deviceData={this.state.device_data} deviceHistorical={this.state.device_historical} classes={this.props.classes} />
                )}
                {this.state.device_data.productType === 'TH_ALARM' && (
                  <AlarmController deviceId={this.state.device_id} deviceData={this.state.device_data} deviceHistorical={this.state.device_historical} classes={this.props.classes} />
                )}
              </React.Fragment>
            )
          }
        </div>

        <div style={{ paddingTop: 65 }} />

        <div className="fixed-bottom" style={{ backgroundColor: '#64BC83' }}>
          <div className="row">
            <div className="text-center col-4 mt-2">
              <Link to="/app/home" className="text-light">
                <h4><FontAwesomeIcon icon={faHome} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/notification" className="text-dark">
                <h4><FontAwesomeIcon icon={faBell} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/settings" className="text-dark">
                <h4><FontAwesomeIcon icon={faCog} /></h4>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(useStyles)(DevicePage);
