import React from 'react';
import { Link } from "react-router-dom";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import apiHandler from '../api/axios-api';
import MaintenanceCard from '../components/maintenance-card';

class NotificationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  async fetchNotifications() {
    apiHandler.get('notifications')
      .then((response) => {
        if (response.status !== 200) {
          return null;
        }
        else {
          return response.data
        }
      }).then((body) => {
        this.setState({
          previous: body.previous,
          next: body.next,
          results: body.results,
          loaded: true,
        });
      });
  }

  async componentDidMount() {
    this.fetchNotifications.bind(this)();
  }

  render() {
    // load notification
    let notifications = [];
    if (this.state.results) {
      notifications = this.state.results;
    }

    return (
      <React.Fragment>
        <div className="fixed-top" style={{ backgroundColor: '#64BC83' }}>
          <MaintenanceCard />
          <div className="row my-2 mx-1">
            <div className="text-left col-12 text-light mt-2">
              <h5>Notification</h5>
            </div>
          </div>
        </div>

        <div className="container">
          {/* <div style={{ paddingTop: 205 }} /> */}
          <div style={{ paddingTop: 77 }} />
          {notifications.length === 0 ? (
            <div className="card mb-2" style={{ backgroundColor: '#e1f1e6' }}>
              <div className="card-body">
                No notification
              </div>
            </div>
          ) :
            <React.Fragment>
              {notifications.map((data, index) => (
                <div key={index} className="card mb-2" style={{ backgroundColor: data.readTime ? 'white' : '#e1f1e6' }}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">{data.title}</div>
                      <div className="col-12">{data.message}</div>
                      <div className="col-12"><small>{new Date(data.notifiedTime).toLocaleString()}</small></div>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="row">
                <div className="text-left col-4 mb-2">
                  <button type="button" className="btn btn-danger" onClick={
                    (event) => {
                      this.fetchNotifications().then(() => { this.forceUpdate(); });
                    }
                  }>Clear</button>
                </div>
                <div className="text-right col-8 mb-2">
                  {this.state.previous &&
                    <button type="button" className="btn btn-info" onClick={
                      (event) => {
                        // TODO: fetch privious page
                      }
                    }>Privious</button>
                  }
                  {this.state.next &&
                    <button type="button" className="btn btn-info ml-3" onClick={
                      (event) => {
                        // TODO: fetch next page
                      }
                    }>Next</button>
                  }
                </div>
              </div> */}
            </React.Fragment>
          }

        </div>

        <div style={{ paddingTop: 65 }} />

        <div className="fixed-bottom" style={{ backgroundColor: '#64BC83' }}>
          <div className="row">
            <div className="text-center col-4 mt-2">
              <Link to="/app/home" className="text-dark">
                <h4><FontAwesomeIcon icon={faHome} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/notification" className="text-light">
                <h4><FontAwesomeIcon icon={faBell} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/settings" className="text-dark">
                <h4><FontAwesomeIcon icon={faCog} /></h4>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default NotificationPage;
