import React from 'react';
import PropTypes from 'prop-types';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import bulb_on from '../images/bulb-on.png';
import bulb_off from '../images/bulb-off.png';
import loading_indicator from '../images/loading-indicator.gif';
import { Collapse } from 'react-collapse';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import { toast } from 'react-toastify';

import apiHandler from '../api/axios-api';

class DimmerController extends React.Component {
  constructor(props) {
    super(props);
    let setting_dict = {};
    if ('settings' in this.props.deviceData) {
      this.props.deviceData.data?.status.forEach((setting) => {
        setting_dict[`power_${setting.channel}`] = setting.power || false;
        setting_dict[`power_man_${setting.channel}`] = setting.power || false;
        setting_dict[`power_request_${setting.channel}`] = false;
        setting_dict[`pattern_${setting.channel}`] = setting.pattern || 0;
      });
      this.props.deviceData.settings.forEach((setting) => {
        setting_dict[`intensity_${setting.channel}`] = setting.intensity || 100;
        setting_dict[`schedule_${setting.channel}`] = setting.schedule?.enable || false;
        setting_dict[`intensity_update_status_${setting.channel}`] = true;

        let api_time = parseInt(setting.schedule?.time);
        if (isNaN(api_time)) {
          api_time = 800;
        }
        let render_time = new Date();
        render_time.setHours((api_time / 100) % 100);
        render_time.setMinutes((api_time) % 100);
        setting_dict[`time_${setting.channel}`] = render_time;

        setting_dict[`duration_${setting.channel}`] = setting.schedule?.duration || 43200;
      });
    }
    this.state = Object.assign({}, {
      device_id: this.props.deviceId,
      device_data: this.props.deviceData,
    }, setting_dict);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.deviceData) !== JSON.stringify(this.props.deviceData)) {
      let setting_dict = {};
      this.props.deviceData.data.status.forEach((setting) => {
        setting_dict[`power_${setting.channel}`] = setting.power || false;
        if (this.state[`power_request_${setting.channel}`]) {
          if (setting_dict[`power_${setting.channel}`] === this.state[`power_man_${setting.channel}`]) {
            setting_dict[`power_request_${setting.channel}`] = false;
            setting_dict[`power_man_${setting.channel}`] = setting_dict[`power_${setting.channel}`];
          }
        }
        else {
          setting_dict[`power_man_${setting.channel}`] = setting_dict[`power_${setting.channel}`];
        }
        setting_dict[`pattern_${setting.channel}`] = setting.pattern || 0;
      });
      let nextState = Object.assign({}, {
        device_data: this.props.deviceData,
      }, setting_dict);
      this.setState(nextState);
    }
  }

  render() {
    // project render
    let proj_100_pattern = [
      0, 365, 511, 495, 381, 254, 238, 124, 222, 206, 92, 214, 198, 16, 146,
    ];
    return (
      <React.Fragment>
        <div>
          {this.state.device_data.productType === 'TH_DIMMER' && String(this.state.device_data.productVariant).trim() === '' && (
            <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'yellowgreen' : 'lightgrey' }}>
              <div className="card-body">
                {this.state.device_data.data && this.state.device_data.data.status.map((channel, index) => (
                  <div key={index} className="row">
                    <div className="text-center col-3 mt-0">
                      <img src={channel.power ? bulb_on : bulb_off} width="70%" alt="LED state icon" />
                    </div>
                    <div className="text-center col-6 mt-3" />
                    <div className="text-center col-3 mt-2">
                      {(this.state.device_data.productVariant !== 'PROJ-100' && channel.power) && (
                        <React.Fragment>
                          {channel.intensity}%
                        </React.Fragment>
                      )}
                      {(this.state.device_data.productVariant === 'PROJ-100' && channel.power) &&
                        proj_100_pattern.map((pattern_value, index) => {
                          if (pattern_value === this.state['pattern_' + channel.channel]) {
                            return (
                              <React.Fragment>
                                {pattern_value === 0 ? 'No Pattern' : `Pattern #${index}`}
                              </React.Fragment>
                            );
                          }
                          else {
                            return (<></>);
                          }
                        })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {this.state.device_data.productType === 'TH_DIMMER' && this.state.device_data.productVariant === '4CH' && (
            <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'yellowgreen' : 'lightgrey' }}>
              <div className="card-body">
                <div className="row">
                  {this.state.device_data.data.status && this.state.device_data.data.status.map((channel, index) => (
                    <div key={index} className="text-center col-3 mt-1">
                      <p className="card-text"><b>CH {channel.channel + 1}</b>
                        <br />
                        <img src={channel.power ? bulb_on : bulb_off} width="70%" alt="LED state icon" />
                        {channel.power && (
                          <React.Fragment>
                            <br />
                            {channel.intensity}%
                          </React.Fragment>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {this.state.device_data.productType === 'TH_DIMMER' && this.state.device_data.productVariant === '3CH' && (
            <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'yellowgreen' : 'lightgrey' }}>
              <div className="card-body">
                <div className="row">
                  {this.state.device_data.data.status && this.state.device_data.data.status.map((channel, index) => (
                    <div key={index} className="text-center col-4 mt-1">
                      <p className="card-text"><b>CH {channel.channel + 1}</b>
                        <br />
                        <img src={channel.power ? bulb_on : bulb_off} width="70%" alt="LED state icon" />
                        {channel.power && (
                          <React.Fragment>
                            <br />
                            {channel.intensity}%
                          </React.Fragment>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {this.state.device_data.productType === 'TH_DIMMER' && this.state.device_data.productVariant === '2CH' && (
            <div className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? 'yellowgreen' : 'lightgrey' }}>
              <div className="card-body">
                <div className="row">
                  {this.state.device_data.data.status && this.state.device_data.data.status.map((channel, index) => (
                    <div key={index} className="text-center col-6 mt-1">
                      <p className="card-text"><b>CH {channel.channel + 1}</b>
                        <br />
                        <img src={channel.power ? bulb_on : bulb_off} width="70%" alt="LED state icon" />
                        {channel.power && (
                          <React.Fragment>
                            <br />
                            {channel.intensity}%
                          </React.Fragment>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}


          {this.state.device_data.settings.map((channel, index) => {
            return (
              <div key={index} className="card mb-2" style={{ backgroundColor: this.state.device_data.online ? '#e1f1e6' : 'lightgrey' }}>
                <div className="card-body">
                  {(this.state.device_data.productVariant === '4CH' || this.state.device_data.productVariant === '3CH' || this.state.device_data.productVariant === '2CH') && (
                    <div className="row">
                      <h5 className="text-left col-12 mb-4">Channel {channel.channel + 1}</h5>
                    </div>
                  )}
                  <div className="row">
                    <div className="text-left col-6 mt-2 mb-3">Power</div>
                    <div className="text-right col-6">
                      {this.state['power_' + channel.channel] === this.state['power_man_' + channel.channel] ?
                        <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state['power_man_' + channel.channel]} onChange={
                          (checked) => {
                            let updated_dict = {};
                            updated_dict['power_man_' + channel.channel] = checked;
                            this.setState(updated_dict);
                            apiHandler.patch('devices/' + this.props.deviceId + '/settings', [
                              {
                                channel: parseInt(channel.channel).toString(),
                                power: checked,
                              }
                            ]).then((response) => {
                              toast.success("Success!");
                            }, (reason) => {
                              toast.error("Unable to perform your request");
                            });

                          }
                        } />
                        :
                        <div className="mt-2">
                          <img src={loading_indicator} alt="Processing request" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                      }
                    </div>
                  </div>
                  <div className="row">
                    {this.state.device_data.productVariant !== 'PROJ-100' && (
                      <React.Fragment>
                        <div className="text-left col-4 mt-3">Intensity</div>
                        <div className="text-left col-8 mt-2">
                          <Slider
                            key={`slider-${channel.channel}`}
                            aria-label="Intensity"
                            defaultValue={this.state['intensity_' + channel.channel]}
                            valueLabelDisplay="auto"
                            step={5}
                            min={0}
                            max={100}
                            onChange={
                              (event) => {
                                let intensity = event.target.value;
                                let updated_dict = {};

                                if (this.state['intensity_' + channel.channel] === intensity) {
                                  return
                                };
                                updated_dict['intensity_' + channel.channel] = intensity;
                                this.setState(updated_dict);
                                apiHandler.patch('devices/' + this.props.deviceId + '/settings', [
                                  {
                                    channel: parseInt(channel.channel).toString(),
                                    intensity: intensity,
                                  }
                                ]).then((response) => {
                                  updated_dict['intensity_update_status_' + channel.channel] = true;
                                  this.setState(updated_dict);
                                }, (reason) => {
                                  updated_dict['intensity_update_status_' + channel.channel] = false;
                                  this.setState(updated_dict);
                                });

                              }
                            }

                            onChangeCommitted={
                              (event) => {
                                if (this.state[`intensity_update_status_${channel.channel}`]) {
                                  toast.success("Success.");
                                } else {
                                  toast.error("Unable to perform your request!");
                                }
                              }
                            }

                          />
                        </div>
                      </React.Fragment>)}
                    {this.state.device_data.productVariant === 'PROJ-100' && (
                      <React.Fragment>
                        <div className="text-left col-3 mt-3">Pattern</div>
                        <div className="text-right col-9 mt-2">
                          {proj_100_pattern.map((pattern_value, index) => (
                            <button type="button" className=
                              {pattern_value === this.state['pattern_' + channel.channel]
                                ? "btn btn-success ml-2 mb-2 col-3" :
                                "btn btn-secondary ml-2 mb-2 col-3"}
                              onClick={
                                (event) => {
                                  apiHandler.patch('devices/' + this.props.deviceId + '/settings', [
                                    {
                                      channel: parseInt(channel.channel).toString(),
                                      pattern: pattern_value,
                                    }
                                  ]).then((response) => {
                                    toast.success("Success!");
                                  }, (reason) => {
                                    toast.error("Unable to perform your request");
                                  });

                                }
                              } >{pattern_value === 0 ? 'Off' : `#${index}`}</button>
                          ))}
                        </div>
                      </React.Fragment>)}
                  </div>
                  <div className="row">
                    <div className="text-left col-6 mt-3">Enable Schedule</div>
                    <div className="text-right col-6 mt-2">
                      <BootstrapSwitchButton onstyle="success" offstyle="danger" width={60} checked={this.state['schedule_' + channel.channel]} onChange={
                        (checked) => {
                          let updated_dict = {};
                          updated_dict['schedule_' + channel.channel] = checked;
                          this.setState(updated_dict);
                          if (!checked) {
                            // only disable schedule when not checked
                            apiHandler.patch('devices/' + this.props.deviceId + '/settings', [
                              {
                                channel: parseInt(channel.channel).toString(),
                                schedule: [
                                  {
                                    enable: checked,
                                  }
                                ],
                              }
                            ]).then((response) => {
                              toast.success("Success!");
                            }, (reason) => {
                              toast.error("Unable to perform your request");
                            });

                          }
                        }
                      } />
                    </div>
                  </div>
                  <Collapse isOpened={this.state['schedule_' + channel.channel]}>
                    <div className="row">
                      <div className="text-left col-7 mt-4">On Time</div>
                      <div className="text-right col-5 mt-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileTimePicker
                            fullWidth={true}
                            label={''}
                            value={this.state['time_' + channel.channel]}
                            onChange={(newValue) => {
                              let updated_dict = {};
                              updated_dict['time_' + channel.channel] = newValue;
                              this.setState(updated_dict);
                            }}
                            renderInput={(params) => <TextField {...params} variant="standard" />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="row">
                      <div className="text-left col-7 mt-4">Duration (Hours)</div>
                      <div className="text-right col-5 mt-3">
                        <Select
                          fullWidth={true}
                          native={true}
                          variant="standard"
                          value={this.state['duration_' + channel.channel]}
                          onChange={
                            (event) => {
                              let updated_dict = {};
                              updated_dict['duration_' + channel.channel] = event.target.value;
                              console.log(updated_dict);
                              this.setState(updated_dict);
                            }
                          }>
                          {[0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((value, index) => (
                            <option key={index} value={value * 3600}>{value}</option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="text-right col-12 mt-3">
                        <button type="button" className="btn btn-info" onClick={
                          (event) => {
                            let render_time = this.state['time_' + channel.channel];
                            let api_time = (render_time.getHours() * 100) + (render_time.getMinutes());
                            apiHandler.patch('devices/' + this.props.deviceId + '/settings', [
                              {
                                channel: parseInt(channel.channel).toString(),
                                schedule: [
                                  {
                                    enable: this.state['schedule_' + channel.channel],
                                    time: api_time,
                                    duration: this.state['duration_' + channel.channel],
                                  }
                                ],
                              }
                            ]).then((response) => {
                              toast.success("Success!");
                            }, (reason) => {
                              toast.error("Unable to perform your request");
                            });

                          }
                        } >
                          Apply Schedule
                        </button>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            )
          })}
        </div>
      </React.Fragment >
    )
  }
}

DimmerController.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceData: PropTypes.object.isRequired,
};

export default DimmerController;
