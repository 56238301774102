import React from 'react';
import { Link } from "react-router-dom";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PasswordStrengthBar from 'react-password-strength-bar';


import apiHandler from '../api/axios-api';
import MaintenanceCard from '../components/maintenance-card';

class ProfilePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      display_name: '',
      currentpassword: '',
      password: '',
      retypepassword: '',
      profile_picture: localStorage.getItem('profile_picture'),
    };
  }

  async fetchDevices() {
    apiHandler.get('profile')
      .then((response) => {
        if (response.status !== 200) {
          return null;
        }
        else {
          return response.data
        }
      }).then((body) => {
        this.setState({
          first_name: body['firstName'],
          last_name: body['lastName'],
          display_name: body['displayName'],
          email: body['username'],
          loaded: true,
        });
      });
  }

  async componentDidMount() {
    this.fetchDevices.bind(this)();
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed-top" style={{ backgroundColor: '#64BC83' }}>
          <MaintenanceCard />
          <div className="row my-2 mx-1">
            <div className="text-left col-9 text-light mt-2">
              <h5>Profile Settings</h5>
            </div>
            <div className="text-right col-3 mt-2">
              <Link to="/app/settings" className="text-light">Back</Link>
            </div>
          </div>
        </div>

        {this.state.loaded &&
          <div className="container">
            {/* <div style={{ paddingTop: 205 }} /> */}
            <div style={{ paddingTop: 77 }} />
            <div className="card mb-2" style={{ backgroundColor: '#e1f1e6' }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-9 text-left">
                    <h5>Personal Information</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-4 mt-2">First Name</div>
                  <div className="text-right col-8 mt-0">
                    <input type="text" className="form-control" placeholder="Your first name" value={this.state.first_name} onChange={
                      (event) => {
                        this.setState({
                          first_name: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-4 mt-4">Last Name</div>
                  <div className="text-right col-8 mt-3">
                    <input type="text" className="form-control" placeholder="Your last name" value={this.state.last_name} onChange={
                      (event) => {
                        this.setState({
                          last_name: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-4 mt-4">Display Name</div>
                  <div className="text-right col-8 mt-3">
                    <input type="text" className="form-control" placeholder="Your display name" value={this.state.display_name} onChange={
                      (event) => {
                        this.setState({
                          display_name: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-4 mt-4">Email Address</div>
                  <div className="text-right col-8 mt-3">
                    <input type="Email" className="form-control" placeholder="Email address" value={this.state.email} readOnly={true} />
                  </div>
                </div>


                <div className="row">
                  <div className="text-left col-4 mt-5">Avatar</div>
                  <div className="text-left col-8 mt-4">
                    {this.state.profile_picture && (
                      <div className="rounded-circle"
                        style={{
                          border: "4px solid #ffffff",
                          width: "150px",
                          height: "150px",
                          backgroundSize: "cover",
                          backgroundPositionX: "center",
                          backgroundPositionY: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundImage: `url(${this.state.profile_picture})`
                        }} />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="text-right col-12 mt-3">
                    <button type="button" className="btn btn-info" onClick={
                      (event) => {
                        apiHandler.patch('profile', {
                          firstName: this.state.first_name,
                          lastName: this.state.last_name,
                          displayName: this.state.display_name,
                        }).then((response) => {
                          // TODO: refresh the token
                        });
                      }
                    } >Save</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-2" style={{ backgroundColor: '#e1f1e6' }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-9 text-left">
                    <h5>Change your Password</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-4 mt-2">Current Password</div>
                  <div className="text-right col-8 mt-0">
                    <input type="password" className="form-control" placeholder="Current password" value={this.state.currentpassword} onChange={
                      (event) => {
                        this.setState({
                          currentpassword: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-4 mt-4">New Password</div>
                  <div className="text-right col-8 mt-3">
                    <input type="password" className="form-control" placeholder="New password" value={this.state.password} onChange={
                      (event) => {
                        this.setState({
                          password: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                {this.state.password !== '' && (
                  <div className="row">
                    <div className="text-left col-4"></div>
                    <div className="text-right col-8">
                      <PasswordStrengthBar password={this.state.password} />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="text-left col-4 mt-4">Re-type Password</div>
                  <div className="text-right col-8 mt-3">
                    <input type="password" className="form-control" placeholder="Re-type new password" value={this.state.retypepassword} onChange={
                      (event) => {
                        this.setState({
                          retypepassword: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                {this.state.currentpassword === '' && this.state.password !== '' && this.state.password === this.state.retypepassword && (
                  <div className="row">
                    <div className="text-left col-4"></div>
                    <div className="text-right col-8 mt-1 text-muted">
                      No current password
                    </div>
                  </div>
                )}
                {this.state.password !== '' && this.state.password !== this.state.retypepassword && (
                  <div className="row">
                    <div className="text-left col-4"></div>
                    <div className="text-right col-8 mt-1 text-muted">
                      Password doesn't match
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="text-right col-12 mt-3">
                    <button type="button" className="btn btn-danger" disabled={this.state.currentpassword === '' || this.state.password === '' || (this.state.password !== this.state.retypepassword)} onClick={
                      (event) => {
                        if (this.state.currentpassword === '') {
                          alert('Please enter current password');
                        }
                        else if (this.state.password === '') {
                          alert('Please enter your password');
                        }
                        else if (this.state.password.length < 6) {
                          alert('Password length must be at least 6 characters');
                        }
                        else if (this.state.password !== this.state.retypepassword) {
                          alert('Re-type Password does not match');
                        }
                        else {
                          apiHandler.patch('changepassword', {
                            currentpassword: this.state.currentpassword,
                            password: this.state.password,
                          }).then((response) => {
                            alert('Password has been changed, you will be logout.');
                            window.location.href = '/app/logout';
                          }).catch((response) => {
                            alert('Unable to change password, please try again later');
                          });
                        }
                      }
                    } >Change Password</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        <div style={{ paddingTop: 65 }} />

        <div className="fixed-bottom" style={{ backgroundColor: '#64BC83' }}>
          <div className="row">
            <div className="text-center col-4 mt-2">
              <Link to="/app/home" className="text-dark">
                <h4><FontAwesomeIcon icon={faHome} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/notification" className="text-dark">
                <h4><FontAwesomeIcon icon={faBell} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/settings" className="text-light">
                <h4><FontAwesomeIcon icon={faCog} /></h4>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment >
    )
  }
}

export default ProfilePage;
