import React from 'react';

class MaintenanceNotice extends React.Component {
  render() {
    return (
      <React.Fragment>

        <div className="container">
          <h2 className="my-2">Maintenance Notice</h2>

          <p>
            Due to the expiration of the current certificate authority, devices with the old certificates will be unable to verify the server certificate starting on August 15, 2024. Consequently, these devices will lose the ability to connect to the server.
          </p>

          <p>
            To address this, we will automatically push updates to the devices to use a new certificate authority. However, it is essential for users to ensure that all their devices are online to receive this update. We kindly request that you put all your devices online to get the latest updates.
          </p>

          <p>
            Please note that we will not be able to recover your device once the certificate has expired.
          </p>

          <p>
            Please review our <a href="/app/tos" title="Terms of Service" >Terms of Service</a> thoroughly.
          </p>

          <p className="mt-4 text-muted">
            July 20, 2024
          </p>

          <p>
            <a href="/app" title="Go Back" >Go Back</a>
          </p>

        </div>
      </React.Fragment>
    )
  }
}

export default MaintenanceNotice;
