import React from 'react';
import { Link } from "react-router-dom";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import apiHandler from '../api/axios-api';
import MaintenanceCard from '../components/maintenance-card';

class DeviceSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device_id: this.props.match.params.device_id,
      device_data: null,
    }
  }

  async fetchDevice() {
    apiHandler.get('devices/' + this.state.device_id)
      .then((response) => {
        if (response.status !== 200) {
          return null;
        }
        else {
          return response.data
        }
      }).then((body) => {
        let versionInt = parseInt(body.version);
        let versionStr = ((versionInt >> 16) & 0xff) + '.' + ((versionInt >> 8) & 0xff) + '.' + ((versionInt >> 0) & 0xff);
        this.setState({
          device_data: body,
          device_name: body.deviceName === this.state.device_id ? '' : body.deviceName,
          rssi: body.rssi,
          version: versionStr,
          loaded: true
        }, () => {
          // console.log(this.state);
        });
      });
  }

  async componentDidMount() {
    this.fetchDevice.bind(this)();
  }

  render() {

    return (
      <React.Fragment>
        <div className="fixed-top" style={{ backgroundColor: '#64BC83' }}>
          <MaintenanceCard />
          <div className="row my-2 mx-1">
            <div className="text-left col-9 text-light mt-2">
              <h5>Device Settings</h5>
            </div>
            <div className="text-right col-3 mt-2">
              <button type="button" onClick={() => this.props.history.goBack()} className="btn btn-link text-light">Back</button>
            </div>
          </div>
        </div>

        {this.state.loaded &&
          <div className="container">
            {/* <div style={{ paddingTop: 205 }} /> */}
            <div style={{ paddingTop: 77 }} />
            <div className="card mb-2" style={{ backgroundColor: '#e1f1e6' }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-9 text-left">
                    <h5>Device Information</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-4 mt-2 mb-3">Software Version</div>
                  <div className="text-left col-8 mt-2 mb-3">
                    {this.state.version}
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-4 mt-2 mb-3">Signal Strength</div>
                  <div className="text-left col-8 mt-2 mb-3">
                    {this.state.rssi} dBm
                  </div>
                </div>
                <div className="row">
                  <div className="text-left col-4 mt-2 mb-3">Device Name</div>
                  <div className="text-right col-8 mt-0 mb-3">
                    <input type="text" className="form-control" placeholder={this.state.device_id} defaultValue={this.state.device_name} onChange={
                      (event) => {
                        this.setState({
                          device_name: event.target.value
                        });
                      }
                    } />
                  </div>
                </div>
                <div className="row">
                  <div className="text-right col-12 mt-3">
                    <button type="button" className="btn btn-info" onClick={
                      (event) => {
                        apiHandler.patch('devices/' + this.state.device_id, {
                          deviceName: this.state.device_name || this.state.device_id,
                        }).then((response) => {
                          this.props.history.goBack();
                        }).catch((e) => {
                        });
                      }
                    } >{this.state.device_name !== '' ? 'Save' : 'Use Serial Number'}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        <div style={{ paddingTop: 65 }} />

        <div className="container fixed-bottom" style={{ backgroundColor: '#64BC83' }}>
          <div className="row">
            <div className="text-center col-4 mt-2">
              <Link to="/app/home" className="text-dark">
                <h4><FontAwesomeIcon icon={faHome} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/notification" className="text-dark">
                <h4><FontAwesomeIcon icon={faBell} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/settings" className="text-light">
                <h4><FontAwesomeIcon icon={faCog} /></h4>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment >
    )
  }
}

export default DeviceSettingsPage;
