import React from 'react';
import { Link } from "react-router-dom";

class MaintenanceCard extends React.Component {
  render() {
    return (
      <></>
      // <Link to={'/app/maintenance-notice'} className="text-decoration-none">
      //   <div className="card text-light bg-danger p-2 m-2">
      //     <h5>Maintenance Notice</h5>
      //     <small>Firmware update required! Please put devices online.</small>
      //     <small>Offline device will not updated and lost connection!</small>
      //     <small className="ml-auto font-italic">Read More..</small>
      //   </div>
      // </Link>
    )
  }
}

export default MaintenanceCard;
