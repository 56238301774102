import axios from 'axios'

// const API_BASE_URL = 'https://api.agrio.agrowdev.com';
// const API_BASE_URL = 'https://api.agrio.agrowlab.com';
// const API_BASE_URL = 'http://127.0.0.1:8000';
const API_BASE_URL = '';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL + '/api/v1/',
  timeout: 60000,
  headers: {
    'Authorization': "Bearer " + localStorage.getItem('access_token'),
    'Content-Type': 'application/json',
    'accept': 'application/json'
  }
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    if (error.response && error.response.data && (error.response.data.code === "token_not_valid" &&
      error.response.status === 401)) {
      const refreshToken = localStorage.getItem('refresh_token');

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);
        console.log(tokenParts.exp);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('token/refresh', { refresh: refreshToken })
            .then((response) => {

              localStorage.setItem('access_token', response.data.access);
              localStorage.setItem('refresh_token', response.data.refresh);

              axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
              originalRequest.headers['Authorization'] = "Bearer " + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch(err => {
              console.log(err)
            });
        } else {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          window.location.href = '/app/login';
        }
      } else {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/app/login';
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
