import React from 'react';
import { Link } from "react-router-dom";

import logo from '../images/logo.png'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaintenanceCard from '../components/maintenance-card';

class SettingsPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="fixed-top" style={{ backgroundColor: '#64BC83' }}>
          <MaintenanceCard />
          <div className="row my-2 mx-1">
            <div className="text-left col-12 text-light mt-2">
              <h5>Settings</h5>
            </div>
          </div>
        </div>

        <div className="container">
          {/* <div style={{ paddingTop: 205 }} /> */}
          <div style={{ paddingTop: 77 }} />
          <div className="card mb-2" style={{ backgroundColor: '#e1f1e6' }}>
            <Link to={'/app/profile'} className="card-block stretched-link text-decoration-none"></Link>
            <div className="card-body">
              Profile
            </div>
          </div>

          {window.ReactNativeWebView !== undefined && (
            <div className="card mb-2" style={{ backgroundColor: '#e1f1e6' }} onClick={
              (event) => {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                  command: 'pairnewdevice',
                  access_token: localStorage.getItem('access_token')
                }));
              }}>
              <div className="card-body">
                New device setup
              </div>
            </div>
          )}

          <div className="card mb-2" style={{ backgroundColor: '#e1f1e6' }}>
            <Link to={'/app/logout'} className="card-block stretched-link text-decoration-none"></Link>
            <div className="card-body">
              Logout
            </div>
          </div>

          <div className="text-center text-muted">
            <div className="mb-3 mt-5"><img src={logo} className="img-fluid" width="40%" alt="Agrio logo" /></div>
            <div>Version 1.6.0</div>
          </div>

          <div className="text-center mt-3">
            <Link to="/app/about" className="text-dark">About</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/app/tos" className="text-dark">Terms of Service</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/app/credits" className="text-dark">Credits</Link>
          </div>

          <div className="mb-4"></div>

        </div>

        <div style={{ paddingTop: 65 }} />

        <div className="fixed-bottom" style={{ backgroundColor: '#64BC83' }}>
          <div className="row">
            <div className="text-center col-4 mt-2">
              <Link to="/app/home" className="text-dark">
                <h4><FontAwesomeIcon icon={faHome} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/notification" className="text-dark">
                <h4><FontAwesomeIcon icon={faBell} /></h4>
              </Link>
            </div>
            <div className="text-center col-4 mt-2">
              <Link to="/app/settings" className="text-light">
                <h4><FontAwesomeIcon icon={faCog} /></h4>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default SettingsPage;
