import React from 'react';
import { Link } from "react-router-dom";
import logo from '../images/logo.png'

import apiHandler from '../api/axios-api'

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'showerror': false
    };
  }

  async handleLogin(event) {
    event.preventDefault();
    let username = document.getElementById('email').value;
    let password = document.getElementById('password').value;
    try {
      const response = await apiHandler.post('token/obtain', {
        username: username,
        password: password
      });
      apiHandler.defaults.headers['Authorization'] = "Bearer " + response.data.access;
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      window.location.href = '/';
    } catch (error) {
      this.setState({
        'showerror': true
      });
      throw error;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div style={{ paddingTop: 100 }} />
          <div className="container">
            <div className="mt-5 px-5 mb-4 text-center">
              <img src={logo} className="img-fluid" alt="Agrio Logo" />
            </div>
            <div className="container">
              <div className="text-center mb-3">
                {
                  this.state.showerror && (
                    <div className="alert alert-danger" role="alert">
                      Wrong email or password, please try again.
                    </div>
                  )
                }
              </div>
              <form noValidate onSubmit={this.handleLogin.bind(this)}>
                <div className="form-group text-muted">
                  <label htmlFor="email">Email address</label>
                  <input type="email" className="form-control" id="email" name="email" aria-describedby="emailHelp" />
                  <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
                <div className="form-group text-muted">
                  <label htmlFor="password">Password</label>
                  <input type="password" className="form-control" id="password" name="password" onChange={(event) => {
                    if (this.state.showerror) {
                      this.setState({
                        'showerror': false
                      });
                    }
                  }} />
                </div>
                <div className="col text-center">
                  <button type="submit" className="btn btn-success">Sign In</button>
                </div>
              </form>
            </div>
          </div>
          <div style={{ paddingTop: 65 }} />
          <div className="text-center">
            <Link to="/app/about" className="text-dark">About</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/app/tos" className="text-dark">Terms of Service</Link>
          </div>
          <div className="mb-4"></div>
        </div>
      </React.Fragment>
    )
  }
}

export default LoginPage;
