import React from 'react';
import PropTypes from 'prop-types';
import bell_on from '../images/cir-ringing-on.png';
import bell_off from '../images/cir-ringing-off.png';
import { format } from 'timeago.js';

class AlarmController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device_data: this.props.deviceData,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.deviceData) !== JSON.stringify(this.props.deviceData)) {
      this.setState({
        device_data: this.getDeviceData()
      });
    }
  }

  getDeviceData() {
    let _deviceData = this.props.deviceData;
    if (!_deviceData.data) {
      _deviceData.data = { status: [] };
    }
    return _deviceData;
  }

  render() {
    const { classes } = this.props;

    // style={{
    //   backgroundColor: this.state.device_data.data && this.state.device_data.online ?
    //     channel.state ? 'orangered' : '#e1f1e6' :
    //     channel.state ? 'indianred' : 'lightgrey'
    // }}>

    return (
      <React.Fragment>
        <div>
          {this.state.device_data.data && this.state.device_data.data.status.map((channel, index) => {

            // alarm card color
            let is_alarm = channel.state ?? false;
            let card_color_class;
            if (this.state.device_data.data && this.state.device_data.online) {
              card_color_class = is_alarm ? classes.online_bgAlert : classes.online_bg;
            }
            else {
              card_color_class = is_alarm ? classes.offline_bgAlert : classes.offline_bg;
            }

            return (
              <div key={index} className={`card mb-2 ${card_color_class}`}>
                <div className="card-body">
                  <div className="row">
                    <div className="text-left col-12 mt-0">
                      <b>{channel.name ? channel.name : 'Alarm #' + (channel.name + 1)}</b>
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-center col-3 mt-3">
                      <img src={channel.state ? bell_on : bell_off} width="70%" alt="Alarm state icon" />
                    </div>
                  </div>
                  <div className="row">
                    {channel.state ?
                      (
                        <div className="text-left col-12 mt-3">
                          Alarmed {format(Date.now() - (channel.alarmedFor))}
                        </div>
                      ) :
                      (
                        <div className="text-left col-12 mt-3">
                          Normal for {format(Date.now() - (channel.goneFor))}
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment >
    )
  }
}

AlarmController.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceData: PropTypes.object.isRequired,
  deviceHistorical: PropTypes.object,
  classes: PropTypes.object,
};

export default AlarmController;
